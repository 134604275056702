import axios from 'axios';

export const rosterService = {
    getRoster
};

function getRoster(leagueId, userId) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'GET',
                        url: process.env.REACT_APP_BASE_URL.concat('/roster'), 
                        headers: { 'authorization': token },
                        params: {
                            'leagueId' : leagueId,
                            'userId' : userId
                        }
                    });
    return res;
}