import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { MuiThemeProvider } from '@material-ui/core/styles';
import customLargeIconButton from '../../styles/themes/customLargeIconButton'

import { userActions } from '../../actions/userActions';

class SettingsMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null
        };
    }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = event => {
    const leagueName = event.currentTarget.innerText;
    if (leagueName.trim() !== "Logout" && leagueName.trim() !== "Admin" && leagueName.trim() !== "" && leagueName.trim() !== this.props.authentication.user.currentLeague.leagueName) {
      this.props.updateCurrentLeague(this.props.authentication.user, event.currentTarget.innerText, false);
    }
    this.setState({ anchorEl: null});
  };

    renderAdmin(isAdmin) {
      if (isAdmin == 'Y') {
          return (
            <div>
              <Divider />
              <Link style={{textDecoration: 'none'}} to="/admin/updateRankings" onClick={this.handleClose}>
                <MenuItem>Admin</MenuItem>
              </Link>
            </div>
          );
      } else {
      return (null);
      }
  }

  render() {
    const { anchorEl } = this.state;

    return (
      <div style={{paddingLeft: 5}}>
        <MuiThemeProvider theme={customLargeIconButton}>
          <IconButton
            aria-owns={anchorEl ? 'asl-settings-menu' : null}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <SettingsIcon />
          </IconButton>
        </MuiThemeProvider>
        <Menu
          id="asl-settings-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {this.props.authentication.user.leagues.map((league) => (
            <MenuItem key={league.leagueName} selected={this.props.authentication.user.currentLeague.leagueName === league.leagueName} 
              onClick={this.handleClose}>{league.leagueName}</MenuItem> ))}
          {this.renderAdmin(this.props.authentication.user.siteAdmin)}
          <Divider />
          <Link style={{textDecoration: 'none'}} to="/logout" onClick={this.handleClose}>
            <MenuItem>Logout</MenuItem>
          </Link>
        </Menu>
      </div>
    );
  }

}

const mapDispatchToProps = dispatch => {
  return {
    updateCurrentLeague: (user, leagueName, redirect) => dispatch(userActions.updateCurrentLeague(user, leagueName, redirect))
  };
};

function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication
    };
}

const connectedSettingsMenu = connect(mapStateToProps,mapDispatchToProps)(SettingsMenu);
export { connectedSettingsMenu as SettingsMenu }; 