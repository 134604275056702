import { createMuiTheme } from '@material-ui/core/styles';

const customLargeIconButton = createMuiTheme({
    overrides:{
        MuiSvgIcon: {
            root: {
                fontSize: '32px',
                fill: '#F8F8F8'
              },
        },
        MuiIconButton: {
            root: {
                '&:hover': {
                    backgroundColor: '#7dba71'
                  }
            }
        }
    }
});
export default customLargeIconButton