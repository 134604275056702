import { eventConstants } from '../constants/eventConstants';

const initialState = {isLoading: true};

export function leagueEvents(state = initialState, action) {
  switch (action.type) {
    case eventConstants.LEAGUE_EVENTS_REQUEST:
      return {
        isLoading: true
      };
    case eventConstants.LEAGUE_EVENTS_SUCCESS:
      return {
        isLoading: false,
        firstEventDateId: action.leagueEvents.leagueEvents[0].eventDateId,
        events: action.leagueEvents.leagueEvents
      };
    case eventConstants.LEAGUE_EVENT_INFO_FAILURE:
      return {
        isLoading: false
      };
    default:
      return state
  }
}