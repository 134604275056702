import axios from 'axios';

export const uploadRankingsService = {
    uploadRankings
};

function uploadRankings(file, eventDateId, complete) {
    var token = localStorage.getItem('id_token');

    var data = new FormData();
    data.append('file', file);
    data.append('eventDateId', eventDateId);
    data.append('complete', complete ? 'Y' : 'N');

    const res = axios({method: 'POST',
                        url: process.env.REACT_APP_BASE_URL.concat('/admin/uploadRankings'), 
                        headers: { 'authorization': token,
                                    'Content-Type': 'multipart/form-data' },
                        data: data,
                        timeout: 4000
                    });

    return res;
}