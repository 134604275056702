import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Route  } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Markdown } from 'react-showdown';

import Spinner from '../universal/spinner';

import { alertActions } from '../../actions/alertActions';
import { commishNotesActions } from '../../actions/commishNotesActions';

class CommishNotesPage extends Component{

    constructor(props) {
        super(props);

        this.state = {
            currentMarkup: '',
            updatedMarkup: '',
            isEditing: false,
            isPreviewing: false,
            dialogOpen: false
        };
    }

  componentDidMount() {
    this.props.getCommishNotes(this.props.authentication.user.currentLeague.leagueId);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.commishNotes.isLoading && this.props.commishNotes != prevProps.commishNotes) {
      this.setState({
          currentMarkup: this.props.commishNotes.commishNotes.commishNotes,
          updatedMarkup: this.props.commishNotes.commishNotes.commishNotes
      });
    }
  }

  editButtonText() {
    return this.state.isEditing ? 'Cancel' : 'Edit';
  }

  previewButtonText() {
    return this.state.isPreviewing ? 'Edit' : 'Preview';
  }

  handleEdit = () =>  {
    if (this.state.isEditing) {
      this.setState({
          updatedMarkup: this.state.currentMarkup,
          isEditing: false,
          isPreviewing: false
      });
    }
    else {
      this.setState({
          isEditing: true
      });
    }
  }

    handleChange = (e) => {
        this.setState({ updatedMarkup: e.target.value });
    }

  handlePreview = () =>  {
    if (this.state.isPreviewing) {
        this.setState({
            isEditing: true,
            isPreviewing: false
        });        
    }
    else {
        this.setState({
            isPreviewing: true
        });
    }
  }

  handleSave = () =>  {
        this.props.updateCommishNotes(this.props.authentication.user.currentLeague.leagueId, this.state.updatedMarkup);
        this.setState({
            isEditing: false,
            isPreviewing: false
        });
  }

  clearDialog = value => {
    this.props.clearAlert();
  };

  renderCommishNotes() {
    if (this.state.isEditing && !this.state.isPreviewing) {
        return (
            <TextField
              id="standard-multiline-static"
              label="Add Commish Notes"
              multiline
              variant="outlined"
              rows="20"
              value={this.state.updatedMarkup}
              onChange={this.handleChange}
              margin="normal"
              style={{width: 400}}
            />
        )
    }
    else
        return (
            <Markdown markup={this.state.updatedMarkup} tables/>
        )
  }

  renderButtons() {
    if (this.props.authentication.user.currentLeague.isOwner == "Y" || this.props.authentication.user.id === 1) {
        return (
                <div style={{display: 'flex', flexDirection: 'column', paddingTop: 30}}>
                    <a href="https://guides.github.com/pdfs/markdown-cheatsheet-online.pdf" target="_blank">Markup Cheatsheet</a>
                    <div style={{display: 'flex', flexDirection: 'row', paddingTop: 10}}>
                        <div style={{width: 115, rightPad: 15}}>
                          <Button style={{width: 100}} variant="raised" onClick={this.handleEdit}>
                            {this.editButtonText()}
                          </Button>
                        </div>
                        <div style={{width: 115, rightPad: 15}}>
                          <Button style={{width: 100}} variant="raised" onClick={this.handlePreview} disabled={!this.state.isPreviewing && this.state.currentMarkup === this.state.updatedMarkup}>
                            {this.previewButtonText()}
                          </Button>
                        </div>
                        <div style={{width: 100}}>
                          <Button style={{width: 100}} variant="raised" onClick={this.handleSave} disabled={this.state.currentMarkup === this.state.updatedMarkup}>
                            Save
                          </Button>
                        </div>
                    </div>
                </div>
            )
        }
    else {
        return null
    }
  }

    render() {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <div style={{width: 370, paddingTop: 30}}>
                    {this.renderCommishNotes()}
                    {this.renderButtons()}
                </div>
                <div>
                    <Dialog
                      open={this.props.alert.showAlert}
                      onClose={this.handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">{this.props.alert.message}</DialogTitle>
                      <DialogActions>
                        <Button onClick={this.clearDialog} color="primary">
                          OK
                        </Button>
                      </DialogActions>
                    </Dialog>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { alert, authentication, commishNotes } = state;
    return {
        alert,
        authentication,
        commishNotes
    };
}

const mapDispatchToProps = dispatch => {
  return {
    getCommishNotes: (leagueId) => dispatch(commishNotesActions.getCommishNotes(leagueId)),
    updateCommishNotes: (leagueId, notes) => dispatch(commishNotesActions.updateCommishNotes(leagueId, notes)),
    clearAlert: () => dispatch(alertActions.clearAlert())
  };
}

const connectedCommishNotesPage = connect(mapStateToProps,mapDispatchToProps)(CommishNotesPage);
export { connectedCommishNotesPage as CommishNotesPage }; 

