import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'babel-polyfill'

import { Asl } from './app/components/asl';
import { HomePage } from './app/components/homepage';
import { Admin } from './app/components/admin';
import { LoginForm } from './app/components/auth/loginForm';
import { PasswordResetForm } from './app/components/auth/passwordResetForm';
import { Logout } from './app/components/auth/logout';
import { PrivateRoute } from './app/helpers/privateRoute';
import reducers from './app/reducers';
import reduxThunk from 'redux-thunk';
import './App.css';
import { unregister } from './registerServiceWorker';

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
const store = createStoreWithMiddleware(reducers);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <div>
          <Route path="/" exact component={HomePage} />
          <PrivateRoute path="/asl" component={Asl} />
          <PrivateRoute path="/admin" component={Admin} />
          <Route path="/signin" component={LoginForm} />
          <Route path="/passwordReset" component={PasswordResetForm} />
          <Route path="/logout" component={Logout} />
      </div>
    </Router>
  </Provider>,
  document.querySelector('#root')
);

unregister();