import { rosterConstants } from '../constants/rosterConstants';
import { rosterService } from '../services/rosterService';
import { alertActions } from './alertActions';

export const rosterActions = {
    getRoster
};

function getRoster(leagueId, userId) {
    return dispatch => {
        dispatch(request());

        rosterService.getRoster(leagueId, userId)
            .then(
                roster => { 
                    dispatch(success(roster.data));

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: rosterConstants.ROSTER_REQUEST, text: "Request Made" } }
    function success(roster) { return { type: rosterConstants.ROSTER_SUCCESS, roster } }
    function failure() { return { type: rosterConstants.ROSTER_FAILURE, text: "Request Failed" } }
}