import { createMuiTheme } from '@material-ui/core/styles';

const customDisabledTab = createMuiTheme({
    overrides:{
        MuiTab: {
            textColorInherit: {
              '&$disabled': {
                opacity: 1.0
              }
            }
        }
    }
});
export default customDisabledTab;