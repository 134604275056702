import React, { Component } from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowLeft from '@material-ui/icons/ArrowLeft';

import Spinner from '../universal/spinner';
import { draftActions } from '../../actions/draftActions';

import { MuiThemeProvider } from '@material-ui/core/styles';
import customDisabledTab from '../../styles/themes/customDisabledTab'
import customBadge from '../../styles/themes/customBadge'

var moment = require('moment-timezone');

class DraftHeader extends Component{

    constructor(props) {
        super(props);
        this.state = {
            remainingHrs: '',
            remainingMins: '',
            remainingSecs: '',
            clockEnd: '',
            value: 0,
            currentDraftRound: 1,
            currentDraftPick: 1,
            ticId: null,
            checkOrderId: null
        };
    }

    componentDidMount() {
        this.props.getDraftOrder(this.props.authentication.user.currentLeague.leagueId, true);
        if (this.props.authentication.user.currentLeague.draftStatus === "Drafting") {
            this.tic();
            const ticId = setInterval(this.tic, 1000);
            const checkOrderId = setInterval(this.checkOrder, 5000);
            this.setState({
                ticId: ticId,
                checkOrderId: checkOrderId
            });
        }
    }

    componentDidUpdate(prevProps) {
      // Typical usage (don't forget to compare props):
      if (!this.props.draftOrder.isLoading && (this.state.currentDraftRound !== this.props.draftOrder.draftOrder.currentDraftRound || 
                                            this.state.currentDraftPick !== this.props.draftOrder.draftOrder.currentDraftPick)) {
        const draftRound = this.props.draftOrder.draftOrder.currentDraftRound;
        const draftPick = this.props.draftOrder.draftOrder.currentDraftPick;
        this.setState({
            clockEnd: this.props.draftOrder.draftOrder.clockEnd,
            currentDraftRound: draftRound,
            currentDraftPick: draftPick
        });
        if (this.props.draftSelection && (this.props.draftSelection.filters || 
                                        this.props.draftSelection.draftSelection.filters)) {
            const eventName = this.props.draftSelection.filters == undefined ? this.props.draftSelection.draftSelection.filters.eventName
                                                                        : this.props.draftSelection.filters.eventName;
            const sportName = this.props.draftSelection.filters == undefined ? this.props.draftSelection.draftSelection.filters.sportName
                                                                        : this.props.draftSelection.filters.sportName;
            const limit = this.props.draftSelection.filters == undefined ? this.props.draftSelection.draftSelection.filters.limit
                                                                        : this.props.draftSelection.filters.limit;
            this.props.getDraftSelection(this.props.authentication.user.currentLeague.leagueId, this.props.authentication.user.id, eventName, sportName, limit);
        }
        this.props.getDraftQueue(this.props.authentication.user.currentLeague.leagueId, this.props.authentication.user.id);
        this.props.getDraftHistory(this.props.authentication.user.currentLeague.leagueId);
      }
    }

    componentWillUnmount() {
        clearInterval(this.state.ticId);
        clearInterval(this.state.checkOrderId);
    }

    tic = () => {
        if (!this.props.draftOrder.isLoading) {
            var currentTime = new moment.utc();
            var clockEnd = new moment.tz(this.props.draftOrder.draftOrder.clockEnd, "UTC");
            var duration = moment.duration(clockEnd.diff(currentTime));
            if (duration >= 0) {
                var remainingHours = Math.floor(duration.asHours()).toString();
                var remainingMins = Math.floor(duration.asMinutes() - (remainingHours * 60)).toString();
                var remainingSecs = Math.floor(duration.asSeconds() - (remainingHours * 60 * 60) - (remainingMins * 60)).toString();
                this.setState({
                    remainingHrs: ('00' + remainingHours).slice(-2),
                    remainingMins: ('00' + remainingMins).slice(-2),
                    remainingSecs: ('00' + remainingSecs).slice(-2)
                });
            }
        }
    }

    checkOrder = () => {
        this.props.getDraftOrder(this.props.authentication.user.currentLeague.leagueId, false);
    }

    renderTeam(team) {
       if (team.autoDrafting === 1) {
            return (
                <MuiThemeProvider theme={customBadge}>
                    <Badge badgeContent="A" color="secondary">
                        <Tab key={team.userId} label={team.teamName} disabled />
                    </Badge>
                </MuiThemeProvider>
            )
       } 
        else {
            return (
                <Tab key={team.userId} label={team.teamName} disabled />   
            )
        }
    }

    renderOnClockTeam(team) {
        if (team.direction === 'Right')
            return (
                <div>
                    <MuiThemeProvider theme={customDisabledTab}>
                        {this.renderTeam(team)}<ArrowRight/>
                    </MuiThemeProvider>
                </div>
            );
        else {
            return (
                <div>
                    <MuiThemeProvider theme={customDisabledTab}>
                        <ArrowLeft/>{this.renderTeam(team)}
                    </MuiThemeProvider>
                </div>
            );
        }
    }

    renderClock() {
        var clock = '';
        if (this.props.authentication.user.currentLeague.draftStatus === "Drafting") {
            clock = this.state.remainingHrs.length === 0 ? '' : this.state.remainingHrs + ':' + this.state.remainingMins + ':' + this.state.remainingSecs
        }
        else {
            const draftStart = new moment.tz(this.props.authentication.user.currentLeague.draftDttm, "UTC");
            clock = 'Draft Date ' + draftStart.local().format('ddd, MMM Do h:mm A');
        }
        return clock;
    }

    renderHeader(){
        if (this.props.authentication.user.currentLeague.draftStatus !== "Drafting") {
          return (<div>
            <div style={{flexGrow: 1, width: '100%'}}>
              <Typography variant="title" align="center" style={{paddingTop: 10, paddingBottom: 10}}>
                {this.renderClock()}
              </Typography>
              </div>
            </div>)
        }
        else if (!this.props.draftOrder.isLoading) {
            return (
                <div>
                  <div style={{flexGrow: 1, width: '100%'}}>
                    <Typography variant="title" align="center" style={{paddingTop: 10, paddingBottom: 10}}>
                      {this.renderClock()}
                    </Typography>
                    <AppBar position="static" color="default">
                          <Tabs
                            value={this.props.draftOrder.draftOrder.selectIndex}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            scrollable
                            scrollButtons="auto"
                          >
                                {this.props.draftOrder.draftOrder.teams.map((team) => (
                                    team.onClock === 1 ?
                                        this.renderOnClockTeam(team) :
                                        this.renderTeam(team)
                                ))}
                          </Tabs>
                    </AppBar>
                  </div>
                </div>
            )
        }
        else {
            return (<div><Spinner/></div>);
        }
    }

    render() {
        return (
            <div>
                {this.renderHeader()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { authentication, draftOrder, draftSelection } = state;
    return {
        authentication,
        draftOrder,
        draftSelection
    };
}

const mapDispatchToProps = dispatch => {
  return {
    getDraftOrder: (leagueId, init) => dispatch(draftActions.getDraftOrder(leagueId, init)),
    getDraftSelection: (leagueId, userId, eventName, sportName, limit) => dispatch(draftActions.getDraftSelection(leagueId, userId, eventName, sportName, limit)),
    getDraftQueue: (leagueId, userId) => dispatch(draftActions.getDraftQueue(leagueId, userId)),
    getDraftHistory: (leagueId) => dispatch(draftActions.getDraftHistory(leagueId))
  };
}

const connectedDraftHeader = connect(mapStateToProps,mapDispatchToProps)(DraftHeader);
export { connectedDraftHeader as DraftHeader }; 