import { sportConstants } from '../constants/sportConstants';

const initialState = {isLoading: true};

export function leagueSports(state = initialState, action) {
  switch (action.type) {
    case sportConstants.SPORTS_REQUEST:
      return {
        isLoading: true
      };
    case sportConstants.SPORTS_SUCCESS:
      return {
        isLoading: false,
        sports: action.sports
      };
    case sportConstants.SPORTS_FAILURE:
      return {
        isLoading: false
      };
    default:
      return state
  }
}