import React from 'react';
import { connect } from 'react-redux';
import { teamsActions } from '../../actions/teamsActions';
import { rosterActions } from '../../actions/rosterActions';

import Spinner from '../universal/spinner'
import TeamSelector from './teamSelector'
import RosterCards from './rosterCards'

class RosterContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        const leagueId = this.props.authentication.user.currentLeague.leagueId;
        const userId = this.props.authentication.user.id;
        this.loadData(leagueId, userId);
    }

    componentDidUpdate(prevProps) {
        const leagueId = this.props.authentication.user.currentLeague.leagueId;
        if (leagueId !== prevProps.authentication.user.currentLeague.leagueId) {
            this.loadData(leagueId, this.props.authentication.user.id);
        }
    }

    loadData(leagueId, userId) {
        this.props.getTeams(leagueId);
        this.props.getRoster(leagueId, userId);       
    }

    renderSelector() {
        if(!this.props.teams.isLoading) {
            return (
                <TeamSelector 
                    selected={this.props.authentication.user.id}
                    leagueId={this.props.authentication.user.currentLeague.leagueId}
                    teams={this.props.teams.teams}
                    getRoster={this.props.getRoster} />
            );
        } else {
        return (<div><Spinner/></div>);
        }
    }

    renderRoster() {
        if(!this.props.roster.isLoading && !this.props.teams.isLoading) {
            return (
                <RosterCards
                    roster={this.props.roster.roster} />
            );
        } else {
        return (<div><Spinner/></div>);
        }
    }

    render() {

        const d1Style = {
              display: 'flex',
              flexDirection: 'row',
              paddingTop: '22px',
              justifyContent: 'center'
        };

        return (
            <div style={d1Style}>
                <div>
                    {this.renderSelector()}
                    {this.renderRoster()}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
  return {
    getTeams: leagueId => dispatch(teamsActions.getTeams(leagueId)),
    getRoster: (leagueId, userId) => dispatch(rosterActions.getRoster(leagueId, userId))
  };
};

function mapStateToProps(state) {
    const { teams, roster, authentication } = state;
    return {
        teams,
        roster,
        authentication
    };
}

const connectedRosterContainer = connect(mapStateToProps,mapDispatchToProps)(RosterContainer);
export { connectedRosterContainer as RosterContainer }; 