export const draftConstants = {
    DRAFT_ORDER_REQUEST: 'DRAFT_ORDER_REQUEST',
    DRAFT_ORDER_SUCCESS: 'DRAFT_ORDER_SUCCESS',
    DRAFT_ORDER_FAILURE: 'DRAFT_ORDER_FAILURE',

    DRAFT_SELECTION_REQUEST: 'DRAFT_SELECTION_REQUEST',
    DRAFT_SELECTION_SUCCESS: 'DRAFT_SELECTION_SUCCESS',
    DRAFT_SELECTION_FAILURE: 'DRAFT_SELECTION_FAILURE',

    DRAFT_USER_REQUEST: 'DRAFT_USER_REQUEST',
    DRAFT_USER_SUCCESS: 'DRAFT_USER_SUCCESS',
    DRAFT_USER_FAILURE: 'DRAFT_USER_FAILURE',

    DRAFT_QUEUE_REQUEST: 'DRAFT_QUEUE_REQUEST',
    DRAFT_QUEUE_SUCCESS: 'DRAFT_QUEUE_SUCCESS',
    DRAFT_QUEUE_FAILURE: 'DRAFT_QUEUE_FAILURE',

    DRAFT_HISTORY_REQUEST: 'DRAFT_HISTORY_REQUEST',
    DRAFT_HISTORY_SUCCESS: 'DRAFT_HISTORY_SUCCESS',
    DRAFT_HISTORY_FAILURE: 'DRAFT_HISTORY_FAILURE'
};