import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Spinner from './universal/spinner'

import { PrivateRoute } from '../helpers/privateRoute';
//import { EventInfoContainer } from './event/eventInfoContainer';
import { StandingsTable } from './standings/standingsTable';
import { RosterContainer } from './roster/rosterContainer';
import { userActions } from '../actions/userActions';
import { EventRankings } from './eventRankings/eventRankings';
import { SettingsMenu } from './universal/settingsMenu';
import { DraftContainer } from './draft/draftContainer';
import { LeagueInfoContainer } from './leagueInfo/leagueInfoContainer';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { MuiThemeProvider } from '@material-ui/core/styles';
import customDraftButton from '../styles/themes/customDraftButton';
import customAppBar from '../styles/themes/customAppBar';
import customMenuButton from '../styles/themes/customMenuButton';

const smallSizes = ['xs','sm'];

class Asl extends Component {

    constructor(props) {
        super(props);

        this.state = {
          open: false,
          selected: 'Roster'
        };
    }

    componentDidMount() {
      if (!this.props.authentication.user) {
        var jwt = localStorage.getItem('id_token');
        this.props.getUserFromJWT(jwt);
      }
      //const script = document.createElement("script");

      //script.src = "https://jsconsole.com/js/remote.js?7dd02b92-250e-4354-9f43-57cb7a2062d6";
      //script.async = true;

      //document.body.appendChild(script);
    }


  toggleDrawer = (isOpen) => () => {
    this.setState({
      open: isOpen
    });
  };

  toggleDrawerClick = (selection,isOpen) => () => {
    this.setState({
      selected: selection,
      open: isOpen
    });
  };

  getPathName = () => {
    const parts = this.props.location.pathname.split("/");
    switch (parts[2]) {
      case 'standings':
        return 'Standings';
      case 'roster':
        return 'Roster';
      case 'eventRankings':
        return 'Events';
      case 'leagueInfo':
        return 'League Info';
      case 'draft':
        return 'Draft';
    }
  }

  renderHeader() {
    if (smallSizes.indexOf(this.props.width) !== -1) {
      return (
        <MuiThemeProvider theme={customAppBar}>
          <AppBar position="fixed">
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="title" style={{paddingLeft: 10, color: '#FFF', width: '100%'}}>
                {this.getPathName()}
              </Typography>
              <SettingsMenu />
            </Toolbar>
          </AppBar>
        </MuiThemeProvider>
      )
    }
    else {
      return (
        <MuiThemeProvider theme={customAppBar}>
          <AppBar position="fixed">
            <Toolbar>
                <Typography variant="title" color="inherit" style={{flexGrow: 1, textAlign: 'left', colorTextPrimary: 'ffffff'}}>
                    <Link style={{color:'white', textDecoration: 'none'}} to="/asl/standings">
                      <MuiThemeProvider theme={customMenuButton}>
                        <Button color="inherit">Standings</Button>
                      </MuiThemeProvider>
                    </Link>
                    <Link style={{color:'white', textDecoration: 'none'}} to="/asl/roster">
                      <MuiThemeProvider theme={customMenuButton}>
                        <Button color="inherit">Roster</Button>
                      </MuiThemeProvider>
                    </Link>
                    <Link style={{color:'white', textDecoration: 'none'}} to="/asl/eventRankings">
                      <MuiThemeProvider theme={customMenuButton}>
                        <Button color="inherit">Events</Button>
                      </MuiThemeProvider>
                    </Link>
                    <Link style={{color:'white', textDecoration: 'none'}} to="/asl/leagueInfo/leagueInfo">
                      <MuiThemeProvider theme={customMenuButton}>
                        <Button color="inherit">League Info</Button>
                      </MuiThemeProvider>
                    </Link>
                    {!this.props.authentication.isLoading && this.props.authentication.user.currentLeague.draftStatus==="Drafting" ? 
                      <Link style={{color:'white', textDecoration: 'none'}} to="/asl/draft/select">
                        <Button variant='contained' color='secondary'>Draft Now!</Button>
                      </Link> : 
                      <Link style={{color:'white', textDecoration: 'none'}} to="/asl/draft/select">
                        <MuiThemeProvider theme={customMenuButton}>
                          <Button color='inherit'>Draft</Button>
                        </MuiThemeProvider>
                      </Link>}
                </Typography>
                <Typography color="inherit">{this.props.authentication.user.email}</Typography>
                <SettingsMenu />
            </Toolbar>
          </AppBar>
        </MuiThemeProvider>
      )
    }

  }

  renderAsl() {

      const { open } = this.state;

      if (!this.props.authentication.isLoading && this.props.authentication.loggedIn) {
        const style1 = {
              flexGrow: 1
          };

          return (
            <div style={style1}>
              {this.renderHeader()}
              <div style={{paddingTop: 60}}>
                <Drawer open={open} onClose={this.toggleDrawer(false)}>
                    <Divider />
                    <List style={{width: 230}}>
                      <Link style={{textDecoration: 'none'}} to="/asl/standings" onClick={this.toggleDrawerClick('Standings', false)}>
                        <ListItem button>
                          <ListItemText primary="Standings" />
                        </ListItem>
                      </Link>
                      <Link style={{textDecoration: 'none'}} to="/asl/roster" onClick={this.toggleDrawerClick('Roster', false)}>
                        <ListItem button>
                          <ListItemText primary="Roster" />
                        </ListItem>
                      </Link>
                      <Link style={{textDecoration: 'none'}} to="/asl/eventRankings" onClick={this.toggleDrawerClick('Events', false)}>
                        <ListItem button>
                          <ListItemText primary="Events" />
                        </ListItem>
                      </Link>
                      <Link style={{textDecoration: 'none'}} to="/asl/leagueInfo/leagueInfo" onClick={this.toggleDrawerClick('League Info', false)}>
                        <ListItem button>
                          <ListItemText primary="League Info" />
                        </ListItem>
                      </Link>
                      {!this.props.authentication.isLoading && this.props.authentication.user.currentLeague.draftStatus==="Drafting" ?
                        <Link style={{textDecoration: 'none'}} to="/asl/draft/select" onClick={this.toggleDrawerClick('Draft', false)}>
                          <MuiThemeProvider theme={customDraftButton}>
                            <ListItem button>
                              <ListItemText primary="Draft Now!" />
                            </ListItem>
                          </MuiThemeProvider>
                        </Link> : 
                        <Link style={{textDecoration: 'none'}} to="/asl/draft/select" onClick={this.toggleDrawerClick('Draft', false)}>
                          <ListItem button>
                            <ListItemText primary="Draft" />
                          </ListItem>
                        </Link>}
                    </List>
                    <Divider />
                    <Link style={{textDecoration: 'none'}} to="/logout" onClick={this.toggleDrawer(false)}>
                      <List>
                        <ListItem button>
                          <ListItemText primary="Logout" />
                        </ListItem>
                      </List>
                    </Link>
                </Drawer>
              </div>
               <PrivateRoute path="/asl/eventRankings" component={EventRankings} />
               <PrivateRoute path="/asl/standings" component={StandingsTable} />
               <PrivateRoute path="/asl/roster" component={RosterContainer} />
               <PrivateRoute path="/asl/draft" component={DraftContainer} />
               <PrivateRoute path="/asl/leagueInfo" component={LeagueInfoContainer} />
            </div>
          );
      } else {
      return (<div><Spinner/></div>);
      }
  }

  render() {
    return(
        <div>
            {this.renderAsl()}
        </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUserFromJWT: jwt => dispatch(userActions.getUserFromJWT(jwt)),
  };
};

function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication
    };
}

const connectedAsl = withWidth()(connect(mapStateToProps,mapDispatchToProps)(Asl));
export { connectedAsl as Asl }; 
