import { standingsConstants } from '../constants/standingsConstants';
import { standingsService } from '../services/standingsService';
import { alertActions } from './alertActions';

export const standingsActions = {
    getStandings
};

function getStandings(leagueId) {
    return dispatch => {
        dispatch(request());

        standingsService.getStandings(leagueId)
            .then(
                standings => { 
                    dispatch(success(standings.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: standingsConstants.STANDINGS_REQUEST, text: "Request Made" } }
    function success(standings) { return { type: standingsConstants.STANDINGS_SUCCESS, standings } }
    function failure() { return { type: standingsConstants.STANDINGS_FAILURE, text: "Request Failed" } }
}