import React, { Component } from 'react';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Spinner from '../universal/spinner';
import Chip from '@material-ui/core/Chip';

import LeagueEventSelector from './leagueEventSelector'
import { eventActions } from '../../actions/eventActions';

export default class EventRankings extends Component {

    componentDidMount() {
        const leagueId = this.props.authentication.user.currentLeague.leagueId;
        this.loadData(leagueId);
    }

    componentDidUpdate(prevProps) {
      // Typical usage (don't forget to compare props):
      var leagueId = this.props.authentication.user.currentLeague.leagueId
      if (leagueId !== prevProps.authentication.user.currentLeague.leagueId) {
        this.loadData(leagueId);
      }
    }

    loadData(leagueId) {
      this.props.getLeagueEvents(leagueId);
    }

    renderSelector() {
        if(!this.props.leagueEvents.isLoading) {
            return (
                <LeagueEventSelector 
                    firstEventDateId={this.props.leagueEvents.firstEventDateId}
                    leagueId={this.props.authentication.user.currentLeague.leagueId}
                    events={this.props.leagueEvents.events}
                    getLeagueEventRankings={this.props.getLeagueEventRankings} />
            );
        } else {
        return (<div><Spinner/></div>);
        }
    }

    renderPoints(points) {
        if (points > 0) {
            return (
                <Typography>
                    {'Points: ' + points}
                </Typography>
            )
        }
        else {
            return null;
        }
    }

    renderOdds(odds, owner) {
      if (owner)
        return odds === '' ? owner : odds + ' | ' + owner
      else
        return odds
    }

    renderEventRankings() {
        if(this.props.leagueEventRankings && !this.props.leagueEventRankings.isLoading) {
            return (
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <List>
                  {this.props.leagueEventRankings.eventRankings.map(event => (
                    <div key={event.eventDateId}>
                      <ListItem>
                        <Chip label={event.eventRank} style = {{flexBasis: '5%'}}/>
                        <ListItemText 
                          primary={event.participantName} 
                          secondary={this.renderOdds(event.participantOdds,event.owner)}/>
                        {this.renderPoints(event.points)}
                      </ListItem>
                      <Divider />
                    </div>
                  ))}
                </List>
              </div>
            );
        } else {
            return (<div><Spinner/></div>);
        }
    };

    render() { 

        const d1Style = {
              display: 'flex',
              flexDirection: 'row',
              paddingTop: '22px',
              justifyContent: 'center'
        };

       return (
            <div style={d1Style}>
                <div>
                    {this.renderSelector()}
                    {this.renderEventRankings()}
                </div>
            </div>
      );
    }
}

const mapDispatchToProps = dispatch => {
  return {
    getLeagueEvents: (leagueId) => dispatch(eventActions.getLeagueEvents(leagueId)),
    getLeagueEventRankings: (LeagueId, eventDateId) => dispatch(eventActions.getLeagueEventRankings(LeagueId, eventDateId)),
  };
};

function mapStateToProps(state) {
    const { leagueEvents, leagueEventRankings, authentication } = state;
    return {
        leagueEvents,
        leagueEventRankings,
        authentication
    };
}

const connectedEventRankings = connect(mapStateToProps,mapDispatchToProps)(EventRankings);
export { connectedEventRankings as EventRankings }; 