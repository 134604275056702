import React, { Component } from 'react';
import { Redirect  } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '../../actions/userActions';

class Logout extends Component{

    componentWillMount() {
        this.props.logout();
    }

    render() {
        return (
            <Redirect to='/signin' />
        );
    }
}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(userActions.logout())
  };
}

const connectedLogout = connect(mapStateToProps,mapDispatchToProps)(Logout);
export { connectedLogout as Logout }; 