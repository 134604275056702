import { eventConstants } from '../constants/eventConstants';

const initialState = {isLoading: true};

export function eventRankings(state = initialState, action) {
  switch (action.type) {
    case eventConstants.EVENT_RANKINGS_REQUEST:
      return initialState;
    case eventConstants.EVENT_RANKINGS_SUCCESS:
      return {
        isLoading: false,
        eventRankings: action.eventRankings
      };
    case eventConstants.EVENT_RANKINGS_FAILURE:
      return {
        isLoading: false
      };
    default:
      return state
  }
}