// user
import { combineReducers } from 'redux';
import { authentication } from './authReducer';
import { passwordReset } from './passwordResetReducer';
import { teams } from './teamsReducer';
import { roster } from './rosterReducer';
import { eventBets } from './eventBetsReducer';
import { leagueEvents } from './leagueEventsReducer';
import { leagueSports } from './sportReducer';
import { eventRankings } from './eventRankingsReducer';
import { leagueEventRankings } from './leagueEventRankingsReducer';
import { draftOrder } from './draftOrderReducer';
import { draftSelection } from './draftSelectionReducer';
import { draftUser } from './draftUserReducer';
import { draftQueue } from './draftQueueReducer';
import { draftHistory } from './draftHistoryReducer';
import { teamInfo } from './teamInfoReducer';
import { leagueInfo } from './leagueInfoReducer';
import { commishNotes } from './commishNotesReducer';
import { alert } from './alertReducer';

// admin
import { events } from './eventsReducer';
import { eventInfo } from './eventInfoReducer';
import { eventTop4 } from './eventTop4Reducer';
import { standings } from './standingsReducer';

import { updateRankings } from './admin/updateRankingsReducer';
import { processRankings } from './admin/processRankingsReducer';

const rootReducer = combineReducers({
  authentication,
  passwordReset,
  teams,
  roster,
  eventBets,
  leagueEvents,
  leagueSports,
  eventRankings,
  leagueEventRankings,
  events,
  eventInfo,
  eventTop4,
  standings,
  draftOrder,
  draftSelection,
  draftUser,
  draftQueue,
  draftHistory,
  teamInfo,
  leagueInfo,
  commishNotes,
  alert,
  updateRankings,
  processRankings
});

export default rootReducer;