import { createMuiTheme } from '@material-ui/core/styles';

const customDisabledTextInput = createMuiTheme({
    overrides:{
      MuiInput: {
        root: {
          '&$disabled': {
            color: 'rgba(0, 0, 0, 0.87)'
          }
        },
        underline: {
          '&$disabled:before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
          }
        }
      },
      MuiFormHelperText: {
        root: {
          '&$disabled': {
            color: 'rgba(0, 0, 0, 0.54)'
          }
        }
      }
    }
});
export default customDisabledTextInput;