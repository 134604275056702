import { teamInfoConstants } from '../constants/teamInfoConstants';

const initialState = {isLoading: true};

export function teamInfo(state = initialState, action) {
  switch (action.type) {
    case teamInfoConstants.TEAM_INFO_REQUEST:
      return initialState;
    case teamInfoConstants.TEAM_INFO_SUCCESS:
      return {
        isLoading: false,
        teamInfo: action.teamInfo
      };
    case teamInfoConstants.TEAM_INFO_FAILURE:
      return {
        isLoading: false,
      };
    default:
      return state
  }
}