import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Route  } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Spinner from '../universal/spinner';

import { alertActions } from '../../actions/alertActions';
import { teamInfoActions } from '../../actions/teamInfoActions';

class TeamInfoPage extends Component{

    constructor(props) {
        super(props);

        this.state = {
            currentName: '',
            updatedName: '',
            validated: true
        };
    }

  componentDidMount() {
    this.props.getTeamInfo(this.props.authentication.user.currentLeague.leagueId,
                              this.props.authentication.user.id);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.teamInfo.isLoading && this.props.teamInfo != prevProps.teamInfo) {
      this.setState({
          currentName: this.props.teamInfo.teamInfo.teamName,
          updatedName: this.props.teamInfo.teamInfo.teamName
      });
    }
  }

  handleSave = () => {
    this.props.updateTeamInfo(this.props.authentication.user.currentLeague.leagueId,
                              this.props.authentication.user.id,
                              this.state.updatedName);
  }

  clearDialog = value => {
    this.props.clearAlert();
  };


  handleChange = event => {
    const newTeamName = event.target.value;
    if (newTeamName.length < 3 || newTeamName.length > 25) {
      this.setState({ validated: false });
    }
    else {
      this.setState({ validated: true });
    }
    this.setState({ updatedName: event.target.value });
  };

    renderValidationMessage() {
      if (!this.state.validated) {
        return (
            <div style={{display: 'flex', justifyContent: 'center', paddingTop: 10}}>
              <Typography style={{paddingTop: 5, align: 'center'}} variant="caption" color="secondary">Team name length between 3 and 25 characters.</Typography>
            </div>
          )
      }
      else {
        return null
      }
    }

    renderTeamInfo() {
        if (!this.props.teamInfo.isLoading) {
            return (
            <div>
                <div style={{display: 'flex', justifyContent: 'center', paddingTop: 40}}>
                    <FormControl variant="outlined" style={{width: 250}}>
                      <InputLabel
                        ref={ref => {
                          this.labelRef = ReactDOM.findDOMNode(ref);
                        }}
                        htmlFor="component-outlined"
                      >
                        Team Name
                      </InputLabel>
                      <OutlinedInput
                        id="component-outlined"
                        value={this.state.updatedName}
                        onChange={this.handleChange}
                        labelWidth={this.labelRef ? this.labelRef.offsetWidth : 0}
                      />
                    </FormControl>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', paddingTop: 10}}>
                  <Button variant="raised" style={{width: 100}} onClick={this.handleSave} disabled={this.state.updatedName === this.state.currentName || !this.state.validated}>
                    Save
                  </Button>
                </div>
                {this.renderValidationMessage()}
                <div>
                    <Dialog
                      open={this.props.alert.showAlert}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">{this.props.alert.message}</DialogTitle>
                      <DialogActions>
                        <Button onClick={this.clearDialog} color="primary">
                          OK
                        </Button>
                      </DialogActions>
                    </Dialog>
                </div>
            </div>
            )
        }
        else {
            return (<div><Spinner/></div>);
        }
    }

    render() {
        return (
            <div>
              {this.renderTeamInfo()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { alert, authentication, teamInfo } = state;
    return {
        alert,
        authentication,
        teamInfo
    };
}

const mapDispatchToProps = dispatch => {
  return {
    getTeamInfo: (leagueId, userId) => dispatch(teamInfoActions.getTeamInfo(leagueId, userId)),
    updateTeamInfo: (leagueId, userId, teamName) => dispatch(teamInfoActions.updateTeamInfo(leagueId, userId, teamName)),
    clearAlert: () => dispatch(alertActions.clearAlert())
  };
}

const connectedTeamInfoPage = connect(mapStateToProps,mapDispatchToProps)(TeamInfoPage);
export { connectedTeamInfoPage as TeamInfoPage }; 