import { createMuiTheme } from '@material-ui/core/styles';

const customDraftButton = createMuiTheme({
    overrides:{
        MuiTypography: {
            subheading: {
                color: 'red'
              },
        },
    }
});
export default customDraftButton