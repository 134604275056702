import { eventConstants } from '../constants/eventConstants';

const initialState = {isLoading: true};

export function eventTop4(state = initialState, action) {
  switch (action.type) {
    case eventConstants.EVENT_TOP4_REQUEST:
      return initialState;
    case eventConstants.EVENT_TOP4_SUCCESS:
      return {
        isLoading: false,
        top4: action.top4
      };
    case eventConstants.EVENT_TOP4_FAILURE:
      return {
        isLoading: false,
        top4: {}
      };
    default:
      return state
  }
}