import { leagueInfoConstants } from '../constants/leagueInfoConstants';

const initialState = {isLoading: true};

export function leagueInfo(state = initialState, action) {
  switch (action.type) {
    case leagueInfoConstants.LEAGUE_INFO_REQUEST:
      return initialState;
    case leagueInfoConstants.LEAGUE_INFO_SUCCESS:
      return {
        isLoading: false,
        leagueInfo: action.leagueInfo
      };
    case leagueInfoConstants.LEAGUE_INFO_FAILURE:
      return {
        isLoading: false,
      };
    default:
      return state
  }
}