import { passwordResetConstants } from '../constants/passwordResetConstants';

const initialState = {isLoading: true};

export function passwordReset(state = initialState, action) {
  switch (action.type) {
    case passwordResetConstants.PASSWORD_RESET_INIT:
      return {
        isLoading: true
      };
    case passwordResetConstants.PASSWORD_RESET_REQUEST:
      return {
        isLoading: true,
      };
    case passwordResetConstants.PASSWORD_RESET_SUCCESS:
      return {
        isLoading: false,
        email: action.data.email,
        validToken: action.data.validToken,
        redirect: action.data.redirect,
        msg: action.data.message
      };
    case passwordResetConstants.PASSWORD_RESET_FAILURE:
      return {
        isLoading: false
      };
    default:
      return state
  }
}