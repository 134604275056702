import { userConstants } from '../constants/userConstants';
import { alertConstants } from '../constants/alertConstants';
import { userService } from '../services/userService';
import { alertActions } from './alertActions';

export const userActions = {
    login,
    getUserFromJWT,
    updateCurrentLeague,
    resetPassword,
    logout,
    postContactUs
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.errorAlert(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function getUserFromJWT(jwt) {
    return dispatch => {
        dispatch(request({ jwt }));

        userService.getUserFromJWT(jwt)
            .then(
                user => { 
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.errorAlert(error));
                }
            );
    };

    function request(jwt) { return { type: userConstants.LOGIN_REQUEST, jwt } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function updateCurrentLeague(user, leagueName, redirectUser) {
    return dispatch => {
        dispatch(request());

        userService.updateCurrentLeague(user, leagueName)
            .then(
                user => {
                    if (redirectUser) {
                        dispatch(redirectUser(user));
                    }
                    else {
                        dispatch(success(user));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.errorAlert(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.LEAGUE_REQUEST, text: "Request Made" } }
    function success(user) { return { type: userConstants.LEAGUE_SUCCESS, user } }
    function redirectUser(user) { return { type: userConstants.LEAGUE_SUCCESS_REDIRECT, user } }
    function failure(error) { return { type: userConstants.LEAGUE_FAILURE, error } }
}

function resetPassword(userId, password) {
    return dispatch => {
        dispatch(request());

        userService.resetPassword(userId, password)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.successAlert("Password reset complete."));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.errorAlert(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.RESET_PASSWORD_REQUEST, text: "Request Made" } }
    function success(user) { return { type: userConstants.RESET_PASSWORD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.RESET_PASSWORD_FAILURE, error } }
}

function postContactUs(email, message) {
    return dispatch => {
        userService.postContactUs(email, message)
            .then(
                res => { 
                    dispatch(alertActions.successAlert(res.data.message));
                },
                error => {
                    dispatch(alertActions.errorAlert(error.toString()));
                }
            );
    };
}

function logout() {
    
    return dispatch => {
        userService.logout();
        dispatch(logout());
    };

    function logout() { return { type: userConstants.LOGOUT, text: "logout" } } 
}