import { commishNotesConstants } from '../constants/commishNotesConstants';
import { commishNotesService } from '../services/commishNotesService';
import { alertActions } from './alertActions';

export const commishNotesActions = {
    updateCommishNotes,
    getCommishNotes
};

function getCommishNotes(leagueId) {
    return dispatch => {
        dispatch(request());

        commishNotesService.getCommishNotes(leagueId)
            .then(
                commishNotes => { 
                    dispatch(success(commishNotes.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: commishNotesConstants.COMMISH_NOTES_REQUEST, text: "Request Made" } }
    function success(commishNotes) { return { type: commishNotesConstants.COMMISH_NOTES_SUCCESS, commishNotes } }
    function failure() { return { type: commishNotesConstants.COMMISH_NOTES_FAILURE, text: "Request Failed" } }
}

function updateCommishNotes(leagueId, commishNotes) {
    return dispatch => {
        dispatch(request());

        commishNotesService.updateCommishNotes(leagueId, commishNotes)
            .then(
                commishNotes => { 
                    dispatch(success(commishNotes.data));
                    dispatch(alertActions.successAlert('Success!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: commishNotesConstants.COMMISH_NOTES_REQUEST, text: "Request Made" } }
    function success(commishNotes) { return { type: commishNotesConstants.COMMISH_NOTES_SUCCESS, commishNotes } }
    function failure() { return { type: commishNotesConstants.COMMISH_NOTES_FAILURE, text: "Request Failed" } }
}