import { eventBetConstants } from '../constants/eventBetConstants';

const initialState = {isLoading: true};

export function eventBets(state = initialState, action) {
  switch (action.type) {
    case eventBetConstants.EVENT_BETS_REQUEST:
      return initialState;
    case eventBetConstants.EVENT_BETS_SUCCESS:
      return {
        isLoading: false,
        totalPoints: action.eventBets.totalPoints,
        updateCount: action.eventBets.updateCount,
        eventBets: action.eventBets.eventBets,
        editableEventBets: action.eventBets.editableEventBets
      };
    case eventBetConstants.EVENT_BETS_FAILURE:
      return {
        isLoading: false,
      };
    default:
      return state
  }
}