import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect  } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { draftActions } from '../../actions/draftActions';
import Spinner from '../universal/spinner';

class DraftQueue extends Component{

    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: false,
            selectedParticipantId: null,
            selectedEventId: null,
            selectedMessage: null
        };
    }

    componentDidMount() {
      if (!(this.props.authentication.user.currentLeague.draftStatus === "Complete")) {
        const leagueId = this.props.authentication.user.currentLeague.leagueId;
        const userId = this.props.authentication.user.id;
        this.props.getDraftQueue(leagueId, userId);  
        this.props.getDraftUser(leagueId, userId);
      }
    }

  handleListItemClick = evt => {
    const things = evt.currentTarget.id.split(";");
    this.setState({ 
        dialogOpen: true,
        selectedParticipantId: things[0],
        selectedEventId: things[1],
        selectedMessage: things[2]
    });
  };

  handleClose = value => {
    this.setState({ 
        dialogOpen: false,
        selectedParticipantId: null,
        selectedEventId: null,
        selectedMessage: null
    });
  };

  handleDeleteQueue = value => {
    this.props.deleteDraftQueue(this.props.authentication.user.currentLeague.leagueId, 
                                  this.props.authentication.user.id, 
                                  this.state.selectedEventId, 
                                  this.state.selectedParticipantId); 
    this.handleClose();
  };

  handleUpdateQueue = direction => value => {
    this.props.updateDraftQueue(this.props.authentication.user.currentLeague.leagueId, 
                                  this.props.authentication.user.id, 
                                  this.state.selectedEventId, 
                                  this.state.selectedParticipantId,
                                  direction); 
    this.handleClose();
  };

  handleDraft = value => {
    const filters = {
      eventName: 'ALL-',
      sportName: 'ALL',
      limit: 200
    }
    this.props.updateDraftSelection(this.props.authentication.user.currentLeague.leagueId, 
                                  this.props.authentication.user.id, 
                                  this.state.selectedEventId, 
                                  this.state.selectedParticipantId, 
                                  filters); 
    this.handleClose();
  };

    renderAutoDraftMessage() {
      if (this.props.draftUser.draftUser.autoDrafting === 1) {
        return (
            <Typography variant="subtitle1" color="secondary">You are currently auto drafting. When it is your turn the highest player, 
                                  or team, in your queue will be choosen. If the queue is empty then the higest 
                                  available overall selection will be made. </Typography>
          )
      }
      else {
        return null
      }
    }

    renderSelections() {
        if (this.props.authentication.user.currentLeague.draftStatus !== "Drafting") {
          return (<Redirect to='/asl/draft/history' />)
        }
        else if (!this.props.draftUser.isLoading && !this.props.draftQueue.isLoading) {
            return (
                <div style={{width: 350, alignContent: 'center'}}>
                  {this.renderAutoDraftMessage()}
                    <List>
                        {this.props.draftQueue.draftQueue.queue.map((draftOption) => (
                            <ListItem key={draftOption.participantName + ' for ' + draftOption.eventName} 
                                    id={draftOption.participantId + ';' + draftOption.eventDateId + ';' + draftOption.participantName + ' for ' + draftOption.eventName} 
                                    divider 
                                    button 
                                    disabled={this.props.draftUser.draftUser.closedEvents.split(',').includes(draftOption.eventDateId.toString()) || 
                                              this.props.draftUser.draftUser.autoDrafting === 1}
                                    onClick={(evt) => this.handleListItemClick(evt)}>
                                <ListItemText primary={draftOption.participantName} 
                                    secondary={draftOption.eventName + ' | ' + draftOption.eventOdds}  />
                            </ListItem>
                        ))}
                    </List>
                    <div>
                        <Dialog
                          open={this.state.dialogOpen}
                          onClose={this.handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{this.state.selectedMessage}</DialogTitle>
                          <DialogActions>
                            <Button onClick={this.handleDraft} color="primary" disabled={this.props.draftUser.draftUser.onClock === 0 ? true : false}>
                              Draft
                            </Button>
                            <Button onClick={this.handleDeleteQueue} color="primary" autoFocus>
                              Delete
                            </Button>
                              <IconButton onClick={this.handleUpdateQueue('up')} aria-label="Delete">
                                <ArrowUpward />
                              </IconButton>
                              <IconButton onClick={this.handleUpdateQueue('down')} aria-label="Delete">
                                <ArrowDownward />
                              </IconButton>
                          </DialogActions>
                        </Dialog>
                    </div>
                </div>
            )
        }
        else {
            return (<div><Spinner/></div>);
        }
    }

    render() {
        return (
            <div style={{display:'flex', flexDirection: 'row', justifyContent: 'center'}}>
                {this.renderSelections()}
            </div>)
    }
}

function mapStateToProps(state) {
    const { authentication, draftUser, draftQueue } = state;
    return {
        authentication,
        draftUser,
        draftQueue
    };
}

const mapDispatchToProps = dispatch => {
  return {
    getDraftQueue: (leagueId, userId) => dispatch(draftActions.getDraftQueue(leagueId, userId)),
    getDraftUser: (leagueId, userId) => dispatch(draftActions.getDraftUser(leagueId, userId)),
    deleteDraftQueue: (leagueId, userId, eventId, participantId) => dispatch(draftActions.deleteDraftQueue(leagueId, userId, eventId, participantId)),
    updateDraftQueue: (leagueId, userId, eventId, participantId, direction) => dispatch(draftActions.updateDraftQueue(leagueId, userId, eventId, participantId, direction)),
    updateDraftSelection: (leagueId, userId, eventId, participantId, filters) => dispatch(draftActions.updateDraftSelection(leagueId, userId, eventId, participantId, filters))
  };
}

const connectedDraftQueue = connect(mapStateToProps,mapDispatchToProps)(DraftQueue);
export { connectedDraftQueue as DraftQueue }; 