import { createMuiTheme } from '@material-ui/core/styles';

const customDivider = createMuiTheme({
    overrides:{
        MuiDivider: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.65)'
              },
        },
    }
});
export default customDivider