import { eventConstants } from '../constants/eventConstants';

const initialState = {isLoading: true};

export function leagueEventRankings(state = initialState, action) {
  switch (action.type) {
    case eventConstants.LEAGUE_EVENT_RANKINGS_REQUEST:
      return initialState;
    case eventConstants.LEAGUE_EVENT_RANKINGS_SUCCESS:
      return {
        isLoading: false,
        eventRankings: action.eventRankings
      };
    case eventConstants.LEAGUE_EVENT_RANKINGS_FAILURE:
      return {
        isLoading: false,
        eventInfo: {},
        participants: {},
        eventRankings: []
      };
    default:
      return state
  }
}