import { uploadRankingsConstants } from '../../constants/admin/uploadRankingsConstants';

const initialState = {isLoading: false};

export function updateRankings(state = initialState, action) {
  switch (action.type) {
    case uploadRankingsConstants.UPLOAD_RANKINGS_REQUEST:
      return {
        isLoading: true,
        fileUploaded: false
      };
    case uploadRankingsConstants.UPLOAD_RANKINGS_SUCCESS:
      return {
        isLoading: false,
        fileUploaded: true,
        fileName: action.fileName
      };
    case uploadRankingsConstants.UPLOAD_RANKINGS_FAILURE:
      return {
        isLoading: false,
        fileUploaded: false
      };
    default:
      return state
  }
}