import React, { Component } from 'react';
import { connect } from 'react-redux';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { draftActions } from '../../actions/draftActions';
import Spinner from '../universal/spinner';

class DraftHistory extends Component{

    componentDidMount() {
        const leagueId = this.props.authentication.user.currentLeague.leagueId;
        this.props.getDraftHistory(leagueId);  
    }

    renderDraftHistory() {
        if (!this.props.draftHistory.isLoading) {
            return (
                <div style={{width: 350, alignContent: 'center'}}>
                    <List>
                        {this.props.draftHistory.draftHistory.rounds.map((round) => (
                            <div>
                                <Typography variant="title" style={{paddingTop: 10, paddingBottom: 10}}>{'Round ' + round.roundNum}</Typography>
                                <Divider />
                                {round.picks.map((pick) => (
                                    <ListItem key={pick.eventName + '|' + pick.participantName} 
                                            id={pick.eventName + '|' + pick.participantName} 
                                            divider >
                                        <ListItemText primary={pick.draftPick + ') ' + pick.teamName + ' - ' + pick.participantName} 
                                            secondary={pick.eventName + ' | ' + pick.eventOdds}  />
                                    </ListItem>
                                ))}
                            </div>
                        ))}
                    </List>
                </div>
            )
        }
        else {
            return (<div><Spinner/></div>);
        }
    }

    render() {
        return (
            <div style={{display:'flex', flexDirection: 'row', justifyContent: 'center'}}>
                {this.renderDraftHistory()}
            </div>)
    }
}

function mapStateToProps(state) {
    const { authentication, draftHistory } = state;
    return {
        authentication,
        draftHistory
    };
}

const mapDispatchToProps = dispatch => {
  return {
    getDraftHistory: (leagueId) => dispatch(draftActions.getDraftHistory(leagueId))
  };
}

const connectedDraftHistory = connect(mapStateToProps,mapDispatchToProps)(DraftHistory);
export { connectedDraftHistory as DraftHistory }; 