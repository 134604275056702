import axios from 'axios';

export const draftService = {
    getDraftOrder,
    getDraftSelection,
    updateDraftSelection,
    getDraftUser,
    updateDraftUser,
    getDraftQueue,
    insertDraftQueue,
    deleteDraftQueue,
    updateDraftQueue,
    getDraftHistory
};

function getDraftOrder(leagueId) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'GET',
                        url: process.env.REACT_APP_BASE_URL.concat('/draftOrder'), 
                        headers: { 'authorization': token },
                        params: {
                            leagueId : leagueId
                        },
                        timeout: 4000
                    });
    return res;
}

function getDraftSelection(leagueId, userId, eventName, sportName, limit) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'GET',
                        url: process.env.REACT_APP_BASE_URL.concat('/draftSelection'), 
                        headers: { 'authorization': token },
                        params: {
                            leagueId: leagueId,
                            userId: userId,
                            eventName: eventName,
                            sportName: sportName,
                            limit: limit
                        },
                        timeout: 4000
                    });
    return res;
}

function getDraftUser(leagueId, userId) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'GET',
                        url: process.env.REACT_APP_BASE_URL.concat('/draftUser'), 
                        headers: { 'authorization': token },
                        params: {
                            leagueId : leagueId,
                            userId: userId
                        },
                        timeout: 4000
                    });
    return res;
}

function updateDraftUser(leagueId, userId, autoDraft) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'POST',
                        url: process.env.REACT_APP_BASE_URL.concat('/draftUser'), 
                        headers: { 'authorization': token,
                                    'Content-Type': 'application/json' },
                        data: {leagueId: leagueId, userId: userId, autoDraft: autoDraft},
                        timeout: 4000
                    });
    return res;
}

function updateDraftSelection(leagueId, userId, eventId, participantId, filters) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'POST',
                        url: process.env.REACT_APP_BASE_URL.concat('/draftSelection'), 
                        headers: { 'authorization': token,
                                    'Content-Type': 'application/json' },
                        data: {leagueId: leagueId, 
                            userId: userId, 
                            eventId: eventId,
                            participantId: participantId,
                            filters: filters},
                        timeout: 4000
                    });
    return res;
}

function getDraftQueue(leagueId, userId) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'GET',
                        url: process.env.REACT_APP_BASE_URL.concat('/draftQueue'), 
                        headers: { 'authorization': token },
                        params: {
                            leagueId : leagueId,
                            userId: userId
                        },
                        timeout: 4000
                    });
    return res;
}

function insertDraftQueue(leagueId, userId, eventId, participantId) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'POST',
                        url: process.env.REACT_APP_BASE_URL.concat('/draftQueue'), 
                        headers: { 'authorization': token,
                                    'Content-Type': 'application/json' },
                        data: {leagueId: leagueId, 
                            userId: userId, 
                            eventId: eventId,
                            participantId: participantId},
                        timeout: 4000
                    });
    return res;
}

function deleteDraftQueue(leagueId, userId, eventId, participantId) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'DELETE',
                        url: process.env.REACT_APP_BASE_URL.concat('/draftQueue'), 
                        headers: { 'authorization': token,
                                    'Content-Type': 'application/json' },
                        data: {leagueId: leagueId, 
                            userId: userId, 
                            eventId: eventId,
                            participantId: participantId},
                        timeout: 4000
                    });
    return res;
}

function updateDraftQueue(leagueId, userId, eventId, participantId, direction) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'PUT',
                        url: process.env.REACT_APP_BASE_URL.concat('/draftQueue'), 
                        headers: { 'authorization': token,
                                    'Content-Type': 'application/json' },
                        data: {leagueId: leagueId, 
                            userId: userId, 
                            eventId: eventId,
                            participantId: participantId,
                            direction: direction},
                        timeout: 4000
                    });
    return res;
}

function getDraftHistory(leagueId) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'GET',
                        url: process.env.REACT_APP_BASE_URL.concat('/draftHistory'), 
                        headers: { 'authorization': token },
                        params: {
                            leagueId : leagueId
                        },
                        timeout: 4000
                    });
    return res;
}