import { standingsConstants } from '../constants/standingsConstants';

const initialState = {isLoading: true};

export function standings(state = initialState, action) {
  switch (action.type) {
    case standingsConstants.STANDINGS_REQUEST:
      return initialState;
    case standingsConstants.STANDINGS_SUCCESS:
      return {
        isLoading: false,
        teams: action.standings,
      };
    case standingsConstants.STANDINGS_FAILURE:
      return {
        isLoading: false,
        standings: {},
      };
    default:
      return state
  }
}