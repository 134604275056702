import { processRankingsConstants } from '../../constants/admin/processRankingsConstants';

const initialState = {isLoading: false};

export function processRankings(state = initialState, action) {
  switch (action.type) {
    case processRankingsConstants.PROCESS_RANKINGS_REQUEST:
      return {
        isLoading: true
      };
    case processRankingsConstants.PROCESS_RANKINGS_SUCCESS:
      return {
        isLoading: false,
        processRankings: action.processRankings
      };
    case processRankingsConstants.PROCESS_RANKINGS_FAILURE:
      return {
        isLoading: false
      };
    default:
      return state
  }
}