import { createMuiTheme } from '@material-ui/core/styles';

const customCheckbox = createMuiTheme({
    overrides:{
        MuiIconButton: {
            root: {
                paddingTop: '0px',
                paddingBottom: '0px'
              },
        },
    }
});
export default customCheckbox