import { createMuiTheme } from '@material-ui/core/styles';

const customBadge = createMuiTheme({
    overrides:{
        MuiBadge: {
            badge: {
                top: 0
            },
            colorSecondary: {
                backgroundColor: 'lightblue'
            }
        }
    }
});
export default customBadge;