import { uploadRankingsConstants } from '../../constants/admin/uploadRankingsConstants';
import { uploadRankingsService } from '../../services/admin/uploadRankingsService';
import { alertActions } from '../alertActions';

export const uploadRankingsActions = {
    uploadRankings
};

function uploadRankings(file, eventDateId, complete) {
    return dispatch => {
        dispatch(request());

        uploadRankingsService.uploadRankings(file, eventDateId, complete)
            .then(
                result => { 
                    if (result.data.status === "Success") {
                        dispatch(success(result.data));
                    }
                    else {
                        dispatch(alertActions.errorAlert(result.data.status));
                        dispatch(failure());
                    }

                },
                error => {
                    //dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: uploadRankingsConstants.UPLOAD_RANKINGS_REQUEST, text: "Request Made" } }
    function success(fileName) { return { type: uploadRankingsConstants.UPLOAD_RANKINGS_SUCCESS, fileName } }
    function failure() { return { type: uploadRankingsConstants.UPLOAD_RANKINGS_FAILURE, text: "Request Failed" } }
}