import axios from 'axios';

export const eventService = {
    getEventInfo,
    getEvents,
    getEventRankings,
    getLeagueEvents,
    getLeagueEventRankings,
    updateEventTop4,
    updateEventTop4Pts,
    updateEventTie,
    updateEventComplete,
    updateEventDate,
    saveEventInfo
};

function getEvents() {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'GET',
                        url: process.env.REACT_APP_BASE_URL.concat('/event'), 
                        headers: { 'authorization': token }
                    });
    return res;
}

function getEventRankings(eventDateId) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'GET',
                        url: process.env.REACT_APP_BASE_URL.concat('/eventRankings'), 
                        headers: { 'authorization': token },
                        params: {
                            eventDateId : eventDateId
                        }
                    });
    return res;
}

function getLeagueEvents(leagueId) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'GET',
                        url: process.env.REACT_APP_BASE_URL.concat('/event/',leagueId), 
                        headers: { 'authorization': token }
                    });
    return res;
}

function getLeagueEventRankings(leagueId, eventDateId) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'GET',
                        url: process.env.REACT_APP_BASE_URL.concat('/leagueEventRankings'), 
                        headers: { 'authorization': token },
                        params: {
                            leagueId : leagueId,
                            eventDateId : eventDateId
                        }
                    });
    return res;
}

function getEventInfo(event) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'GET',
                        url: process.env.REACT_APP_BASE_URL.concat('/eventInfo/',event), 
                        headers: { 'authorization': token }
                    });
    return res;
}

function saveEventInfo(eventInfo, eventTop4) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'POST',
                        url: process.env.REACT_APP_BASE_URL.concat('/eventInfo/',eventInfo.eventInfo.name), 
                        headers: { 'authorization': token,
                                    'Content-Type': 'application/json' },
                        data: {eventInfo: eventInfo, eventTop4: eventTop4}
                    });
    return res;
}

function updateEventTie(participants, index, checked) {
    const p = Array.from(participants);
    const idx = parseInt(index, 10);
    p[idx].tie = checked ? 1 : 0
    var rank = p[idx-1].rank

    for (var x = idx; x < p.length; x++) {
        rank = p[x].tie ? rank : rank + 1;
        p[x].rank = rank; 
        p[x].idx = x; 
    }
    return updateEventTop4(p);
}

function updateEventTop4(participants) {
    var top4 = [];
    var ownedCounter = 0;
    var x = 0
    while (ownedCounter < 4) {
        top4.push({
            participant_id: participants[x].participant_id,
            name: participants[x].name,
            rank: participants[x].rank,
            tie: participants[x].tie,
            owner: participants[x].owner,
            points: participants[x].points
        });
        if (participants[x].owner && participants[x].tie===0) {
            ownedCounter++;
        }
        x++;
    }
    return top4;
}

function updateEventTop4Pts(top4, idx, pts) {
    top4[idx].points = parseInt(pts.points, 10);
    return top4;
}

function updateEventComplete(eventInfo) {
    eventInfo.eventInfo.final = eventInfo.eventInfo.final === "N" ? "Y" : "N";
    return eventInfo;
}

function updateEventDate(eventInfo, date) {
    eventInfo.eventInfo.date = date;
    return eventInfo;
}