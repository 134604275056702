import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Spinner from './universal/spinner'

import { PrivateRoute } from '../helpers/privateRoute';
import { UpdateRankingsContainer } from './admin/updateRankings/updateRankingsContainer';
import { userActions } from '../actions/userActions';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { MuiThemeProvider } from '@material-ui/core/styles';
import customDraftButton from '../styles/themes/customDraftButton'

const smallSizes = ['xs','sm'];

class Admin extends Component {

    constructor(props) {
        super(props);

        this.state = {
          open: false,
          selected: 'Update Rankings'
        };
    }

    componentDidMount() {
      if (!this.props.authentication.user) {
        var jwt = localStorage.getItem('id_token');
        this.props.getUserFromJWT(jwt);
      }
      //const script = document.createElement("script");

      //script.src = "https://jsconsole.com/js/remote.js?7dd02b92-250e-4354-9f43-57cb7a2062d6";
      //script.async = true;

      //document.body.appendChild(script);
    }


  toggleDrawer = (isOpen) => () => {
    this.setState({
      open: isOpen
    });
  };

  toggleDrawerClick = (selection,isOpen) => () => {
    this.setState({
      selected: selection,
      open: isOpen
    });
  };

  getPathName = () => {
    const parts = this.props.location.pathname.split("/");
    switch (parts[2]) {
      case 'updateRankings':
        return 'Update Rankings';
    }
  }

  renderHeader() {
    if (smallSizes.indexOf(this.props.width) !== -1) {
      return (
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="title" style={{paddingLeft: 10, color: '#FFF', width: '100%'}}>
              {this.getPathName()}
            </Typography>
          </Toolbar>
        </AppBar>
      )
    }
    else {
      return (
        <AppBar position="fixed">
          <Toolbar>
              <Typography variant="title" color="inherit" style={{flexGrow: 1, textAlign: 'left', colorTextPrimary: 'ffffff'}}>
                  <Link style={{color:'white'}} to="/admin/updateRankings"><Button color="inherit">Update Rankings</Button></Link>
              </Typography>
              <Typography variant="title" color="inherit" style={{colorTextPrimary: 'ffffff'}}>
                  <Link style={{color:'white'}} to="/asl/roster"><Button color="inherit">Back to Leagues</Button></Link>
              </Typography>
          </Toolbar>
        </AppBar>
      )
    }

  }

  renderAdmin() {

      const { open } = this.state;

      if (!this.props.authentication.isLoading) {
        const style1 = {
              flexGrow: 1
          };

          return (
            <div style={style1}>
              {this.renderHeader()}
              <div style={{paddingTop: 60}}>
                <Drawer open={open} onClose={this.toggleDrawer(false)}>
                    <Divider />
                    <List style={{width: 230}}>
                      <Link style={{textDecoration: 'none'}} to="/admin/updateRankings" onClick={this.toggleDrawerClick('Rankings', false)}>
                        <ListItem button>
                          <ListItemText primary="Rankings" />
                        </ListItem>
                      </Link>
                    </List>
                    <Divider />
                    <Link style={{textDecoration: 'none'}} to="/logout" onClick={this.toggleDrawer(false)}>
                      <List>
                        <ListItem button>
                          <ListItemText primary="Logout" />
                        </ListItem>
                      </List>
                    </Link>
                </Drawer>
              </div>
               <PrivateRoute path="/admin/updateRankings" component={UpdateRankingsContainer} passReset={this.props.authentication.user.passwordReset} />
            </div>
          );
      } else {
      return (<div><Spinner/></div>);
      }
  }

  render() {
    return(
        <div>
            {this.renderAdmin()}
        </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUserFromJWT: jwt => dispatch(userActions.getUserFromJWT(jwt)),
  };
};

function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication
    };
}

const connectedAdmin = withWidth()(connect(mapStateToProps,mapDispatchToProps)(Admin));
export { connectedAdmin as Admin }; 
