import { draftConstants } from '../constants/draftConstants';

const initialState = {isLoading: true,
                      filters: {eventName: 'ALL-',
                                sportName: 'ALL',
                                limit: 200}};

export function draftSelection(state = initialState, action) {
  switch (action.type) {
    case draftConstants.DRAFT_SELECTION_REQUEST:
      return {isLoading: true,
            filters: action.filters};
    case draftConstants.DRAFT_SELECTION_SUCCESS:
      return {
        isLoading: false,
        draftSelection: action.draftSelection,
      };
    case draftConstants.DRAFT_SELECTION_FAILURE:
      return {
        isLoading: false,
      };
    default:
      return state
  }
}