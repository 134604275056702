import { teamInfoConstants } from '../constants/teamInfoConstants';
import { teamInfoService } from '../services/teamInfoService';
import { alertActions } from './alertActions';

export const teamInfoActions = {
    updateTeamInfo,
    getTeamInfo
};

function getTeamInfo(leagueId, userId) {
    return dispatch => {
        dispatch(request());

        teamInfoService.getTeamInfo(leagueId, userId)
            .then(
                teamInfo => { 
                    dispatch(success(teamInfo.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: teamInfoConstants.TEAM_INFO_REQUEST, text: "Request Made" } }
    function success(teamInfo) { return { type: teamInfoConstants.TEAM_INFO_SUCCESS, teamInfo } }
    function failure() { return { type: teamInfoConstants.TEAM_INFO_FAILURE, text: "Request Failed" } }
}

function updateTeamInfo(leagueId, userId, teamName) {
    return dispatch => {
        dispatch(request());

        teamInfoService.updateTeamInfo(leagueId, userId, teamName)
            .then(
                teamInfo => { 
                    dispatch(success(teamInfo.data));
                    dispatch(alertActions.successAlert(teamInfo.data.msg));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: teamInfoConstants.TEAM_INFO_REQUEST, text: "Request Made" } }
    function success(teamInfo) { return { type: teamInfoConstants.TEAM_INFO_SUCCESS, teamInfo } }
    function failure() { return { type: teamInfoConstants.TEAM_INFO_FAILURE, text: "Request Failed" } }
}