import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import customCheckbox from '../../../styles/themes/customCheckbox'
import customSvgIcon from '../../../styles/themes/customSvgIcon'
import BackupIcon from '@material-ui/icons/Backup';
import GetAppIcon from '@material-ui/icons/GetApp';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { CSVLink, CSVDownload } from "react-csv";

export default class UploadRankings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            file: null,
            complete: false
        };
    }

    handleUploadClick = event => {
        this.props.handleUpload(this.state.file, this.state.complete);
    };

    handleChange = name => event => {
        this.setState({ [name]: event.currentTarget.checked});
        if (name == "complete") {
            this.props.clickComplete(event.currentTarget.checked)
        }
    };

    handleSelectedFile = event => {
        this.setState({
            file: event.target.files[0]
        })
    }

    now_to_string(){
        const d = new Date();
        return d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate()+'-'+d.getHours()+'-'+d.getMinutes();
    }

    remove_space_and_quotes(eventName) {
        const find_space = ' ';
        const find_quote = /'/;
        const re_space = new RegExp(find_space, 'g');
        const re_quote = new RegExp(find_quote, 'g');
        return eventName.replace(re_space, '_').replace(re_quote,'');
    }

    get_file_name_download(eventName) {
        return this.remove_space_and_quotes(eventName) + '-' + this.now_to_string() + '.csv';
    }

    render() {
        return (
        <div style={{display: 'flex', flexDirection: 'column', paddingTop: '20px', alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <div style={{display: 'flex', flexDirection: 'column', paddingLeft: '50px'}}>
                    <input style={{paddingLeft: '20px', width: '0.1px', height: '0.1px', opacity: 0, overflow: 'hidden', position: 'absolute', zIndex: -1}} 
                        type="file" name="file" id="file" accept=".csv" onChange={this.handleSelectedFile} />
                    <label for="file" style={{cursor: 'pointer', 
                                            fontWeight: this.state.file ? 1000 : 400,
                                            color: this.state.file ? 'darkorange' : 'black'}}>
                        <MuiThemeProvider theme={customSvgIcon}>
                            <BackupIcon color="action"/>
                        </MuiThemeProvider>
                        {this.state.file == null ? 'Choose a file' : this.state.file.name}
                    </label>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <CSVLink filename={this.get_file_name_download(this.props.eventName)}  separator=',' uFEFF={false}
                                data={this.props.eventRankings} 
                                style={{color: 'black', textDecoration: 'none'}}>
                            <MuiThemeProvider theme={customSvgIcon}>
                                <GetAppIcon color="action"/>Download
                            </MuiThemeProvider>
                        </CSVLink>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', paddingLeft: '50px', paddingTop: '10px'}}>
                    <MuiThemeProvider theme={customCheckbox}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.state.complete}
                            color="primary"
                            onChange={this.handleChange('complete')}
                        />
                        }
                        label="Complete"
                    />
                    </MuiThemeProvider>
                </div>
            </div>
            <Typography style={{paddingTop: '25px', paddingBottom: '5px'}} variant="caption" color="secondary">
            File must be type of csv (with header) and include 3 columns (team, odds, and place.)
            </Typography>
            <Typography style={{paddingBottom: '5px'}} variant="caption" color="secondary">
            Check <b><i>complete</i></b> if this is the final update and points are to be assigned.
            </Typography>
            <Typography style={{paddingBottom: '5px'}} variant="caption" color="secondary">
            Must assign places if <b><i>complete</i></b> is checked (can be multiple 2-4 representing ties, use two 3rds if no 3rd/4th place differentiator)
            </Typography>
            <Typography style={{paddingBottom: '25px'}} variant="caption" color="secondary">
            Default 0 in the <b><i>Places</i></b> column for non-completed events and -1 can signify eleminated from contention.
            </Typography>
            <Button onClick={this.handleUploadClick} variant="contained" color="primary" disabled={this.state.file == null ? true : false}>
            Next
            </Button>
        </div>
    )}

}

UploadRankings.propTypes = {
    handleUpload: PropTypes.func,
    eventRankings: PropTypes.object,
    clickComplete: PropTypes.func,
    eventName: PropTypes.string
  }