import React, { Component } from 'react';
import { connect } from 'react-redux';
import background from '../../background.jpg';
import backgroundSmall from '../../background-small.jpg';
import facebook from '../../facebook.png';
import twitter from '../../twitter.png';
import withWidth from '@material-ui/core/withWidth';

import { PrivateRoute } from '../helpers/privateRoute';
import { userActions } from '../actions/userActions';
import { passwordResetActions } from '../actions/passwordResetActions';
import { alertActions } from '../actions/alertActions';
import { RosterContainer } from './roster/rosterContainer';
import Spinner from './universal/spinner'
import AlertDialog from './universal/alertDialog'

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { MuiThemeProvider } from '@material-ui/core/styles';
import customSigninExpansion from '../styles/themes/customSigninExpansion';
import customMenuButton from '../styles/themes/customMenuButton';
import customMenuCreateButton from '../styles/themes/customMenuCreateButton';

import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const smallSizes = ['xs','sm'];

class HomePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            showPassReset: false,
            emailValid: false,
            loginEmailValid: false,
            showInvalidEmail: false,
            contactOpen: false,
            leagues: [],
            expansionOpen: false,
            comingSoonOpen: false,
            contactEmail: "",
            contactEmailValid: false,
            contactMessage: ""
          };
    };

    componentDidMount() {
        if (!this.props.authentication.user) {
          var jwtToken = localStorage.getItem('id_token');
          jwtToken !== null && this.props.getUserFromJWT(jwtToken);
          
          window.addEventListener('scroll', this.closeOnScroll);
        }
    };

    componentDidUpdate(prevProps) {
        if(!this.props.authentication.isLoading && this.props.authentication.redirect) {
            this.props.history.push('/asl/roster');
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.closeOnScroll);
    }

    closeOnScroll = () => {
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (!(isMobile)) {
            this.setState({
                expansionOpen: false
            });
        }
    }

    closeMenu = () => {
        this.setState({
            expansionOpen: false
        });
    }

    handleComingSoonClose = () => {
        this.setState({
            comingSoonOpen: false
        });
    }

    handleComingSoonOpen = () => {
        this.setState({
            expansionOpen: false,
            comingSoonOpen: true
        });
    }

    emailValid(email) {
        //return value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    handleLogin = (e) => {
        this.props.login(this.state.username, this.state.password);
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        if (name == "username") {
            this.setState({ loginEmailValid: this.emailValid(value) });
        }
        else if (name == "contactEmail") {
            this.setState({ contactEmailValid: this.emailValid(value) });
        }
    };

    passResetClick = e => {
        e.preventDefault();
        if (this.state.loginEmailValid) {
            this.setState({ showPassReset: true });
        }
        else {
            this.setState({ showInvalidEmail: true });
        }
    }

    cancelPassReset = e => {
        this.setState({ showPassReset: false });
    }

    okInvalidEMail = e => {
        this.setState({ showInvalidEmail: false });
    }

    handleContactOpen = () => {
        this.setState({ contactOpen: true });
      };
    
    handleContactClose = () => {
        this.setState({ contactOpen: false,
                        contactEmail: "",
                        contactMessage: "" });
    };

    handleContactSend = () => {
        this.props.contactUs(this.state.contactEmail, this.state.contactMessage);
        this.handleContactClose();
    };

    submitPassReset = e => { 
        this.setState({ showPassReset: false });
        this.props.sendPasswordResetEmail(this.state.username);
    }    

    handleErrorClose = e => {
        e.preventDefault();
        this.props.clearAlert();
    }

    handleMenuClick = event => {
        if (this.props.authentication.user.currentLeague.leagueName != event.currentTarget.innerText) {
            this.props.updateCurrentLeague(this.props.authentication.user, event.currentTarget.innerText, true);
        }
        else {
            this.props.history.push('/asl/roster');
        }
    };

    renderTextVariant = (text) => {
        if (smallSizes.indexOf(this.props.width) == -1) {
            return (
                <Typography style={{paddingTop:'20px'}} align="center" color="inherit" component="h2" variant="h5">{text}</Typography>
            )
        }
        else {
            return (
                <Typography style={{paddingTop:'20px'}} align="center" color="inherit" component="h2" variant="h6">{text}</Typography>
            )
        }
    }

    renderTextVariantLarge = (text) => {
        if (smallSizes.indexOf(this.props.width) == -1) {
            return (
                <Typography style={{paddingTop:'20px'}} align="center" color="inherit" component="h2" variant="h4">{text}</Typography>
            )
        }
        else {
            return (
                <Typography style={{paddingTop:'20px'}} align="center" color="inherit" component="h2" variant="h5">{text}</Typography>
            )
        }
    }

    renderDropDown() {
        if (this.props.authentication.isLoading) {
            return (<Spinner/>)
        }
        else if (!this.props.authentication.user) {
            return this.renderSignin() 
        }
        else {
            return (
                <MuiThemeProvider theme={customSigninExpansion}>
                <ExpansionPanel square={false} expanded={this.state.expansionOpen}>
                    <ExpansionPanelSummary onClick={() => {
                                                    this.setState({
                                                        expansionOpen: !this.state.expansionOpen
                                                    });
                                                }}
                        expandIcon={<ExpandMoreIcon />}>
                        <Typography>Leagues</Typography>
                    </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center'}}>
                                <MuiThemeProvider theme={customMenuButton}>
                                    {this.props.authentication.user.leagues.map((league) => (
                                        <Button style={{width: '100%'}} key={league.leagueName}
                                            onClick={this.handleMenuClick}>{league.leagueName}
                                        </Button> 
                                        ))}
                                </MuiThemeProvider>
                                <div style={{paddingTop: '20px', width: '80%'}}>
                                    <Button style={{width: '100%'}} 
                                        variant="raised" 
                                        onClick={this.handleComingSoonOpen}>
                                            Create League
                                    </Button>
                                </div>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </MuiThemeProvider>
            )
        }
    };

    renderSignin() {
        return (
            <MuiThemeProvider theme={customSigninExpansion}>
                <ExpansionPanel square={false} expanded={this.state.expansionOpen}>
                    <ExpansionPanelSummary onClick={() => {
                                                    this.setState({
                                                        expansionOpen: !this.state.expansionOpen
                                                    });
                                                }}
                        expandIcon={<ExpandMoreIcon />}>
                        <Typography>Signin</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div style={{width:'100%'}}>
                            <div>
                                <div>
                                    <TextField label="Email" name="username" type="email" fullWidth onChange={this.handleChange} error={!(this.state.loginEmailValid)}/>
                                </div>
                                <div>
                                    <TextField label="Password" name="password" type="password" fullWidth onChange={this.handleChange} error={this.state.password.length <= 2}/>
                                </div>
                                <div style={{paddingTop: '10px', display: 'flex', justifyContent: 'space-between', width:'100%'}}>
                                    <div style={{width:'100px'}}>
                                        <Button variant="raised" onClick={this.handleLogin} fullWidth 
                                        disabled={!(this.state.loginEmailValid) || this.state.password.length <= 2}>Signin</Button>
                                    </div>
                                    <div style={{width:'100px'}}>
                                        <Button variant="raised" fullWidth onClick={this.handleComingSoonOpen}>Join</Button>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', paddingTop:'15px'}}>
                                <a style={{textDecoration: 'none'}} href="#" onClick={this.passResetClick}>
                                    <Typography variant='caption' style={{color: 'whitesmoke'}}>Forgot Password</Typography>
                                </a>
                            </div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </MuiThemeProvider>
        )
    };

    renderBackground = () => {
        if (smallSizes.indexOf(this.props.width) == -1) { 
            return (
                <div style={{backgroundImage: "url("+background+")", 
                            backgroundSize: 'cover',
                            backgroundPosition: '100%',
                            position: 'absolute',
                            width: '100%', 
                            height:'100%'}}>
                    <img style={{position:'absolute', 
                                height: '100%', 
                                width: '100%', 
                                objectPosition: 'center center',
                                objectFit: 'cover'}} 
                        src={background} alt="Logo" />
                </div>
            )
        }
        else {
            return (
                <div style={{backgroundImage: "url("+backgroundSmall+")", 
                            backgroundSize: 'cover',
                            backgroundPosition: '100%',
                            position: 'absolute',
                            width: '100%', 
                            height:'100%'}}>
                    <img style={{position:'absolute', 
                                height: '100%', 
                                width: '100%', 
                                objectPosition: 'center center',
                                objectFit: 'cover'}} 
                        src={backgroundSmall} alt="Logo" />
                </div>
            )
        }

    }

    render() {
        return (
            <div>
                <div style={{position: 'fixed', top: '1%', right: '1%', width: '275px', zIndex: 2}}>
                    {this.renderDropDown()}
                </div>
                <div style={{backgroundSize: 'cover', 
                            height: '100%', 
                            width: '100%'}}
                    onClick={ this.closeMenu }>
                        {this.renderBackground()}
                </div>
                <div style={{display: 'flex', flexDirection: 'column', position:'absolute', top: '100%', height:'100%', width:'100%', paddingTop: '50px', overflowX: 'hidden', backgroundColor: '#E0E0E0'}}
                    onClick={ this.closeMenu }>
                    <ScrollAnimation animateIn='bounceInRight'>
                        {this.renderTextVariantLarge('Year-round fantasy sports league')}
                    </ScrollAnimation>
                    <div style={{paddingTop:'10px'}}></div>
                    <ScrollAnimation delay={500} animateIn='bounceInLeft'>
                        {this.renderTextVariant('Over 30 sporting events')}
                    </ScrollAnimation>
                    <ScrollAnimation delay={500} animateIn='bounceInRight'>
                        {this.renderTextVariant('Battle in a large multi-day draft')}
                    </ScrollAnimation>
                    <ScrollAnimation delay={500} animateIn='bounceInLeft'>
                        {this.renderTextVariant('Stay current on the sports all year')}
                    </ScrollAnimation>
                    <ScrollAnimation delay={500} animateIn='bounceInRight'>
                        {this.renderTextVariantLarge('100% FREE to play')}
                    </ScrollAnimation>
                    <div style={{display: 'flex', 
                                flexDirection: 'row', 
                                height: '100%', 
                                alignItems: 'flex-end', 
                                justifyContent: 'space-between',
                                paddingLeft: '5%',
                                paddingRight: '5%',
                                paddingBottom: '25px'}}>
                        <Button style={{fontSize: '0.875rem', fontFamily: 'Roboto, Helvetica, Arial, sans-serif'}} onClick={this.handleContactOpen}>Contact Us</Button>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{paddingRight: '10px'}}><a href='http://facebook.com'><img style={{width: '30px', height: '30px'}} src={facebook}/></a></div>
                            <div><a href='http://twitter.com'><img style={{width: '30px', height: '30px'}} src={twitter}/></a></div>
                        </div>
                    </div>
                </div>
                <Dialog
                    open={this.state.contactOpen}
                    TransitionComponent={Transition}
                    onClose={this.handleContactClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    >
                    <DialogTitle id="alert-dialog-slide-title">
                        {"Feedback is always appreciated"}
                    </DialogTitle>
                    <DialogContent>
                        <TextField label="Email" name="contactEmail" type="email" fullWidth 
                            disabled={this.props.authentication.loggedIn} 
                            defaultValue={this.props.authentication.loggedIn ? this.props.authentication.user.email : ""} 
                            error={!(this.props.authentication.loggedIn || this.state.contactEmailValid) }
                            onChange={this.handleChange}/>
                        <TextField 
                            inputProps={{
                                maxLength: 1000
                             }}
                            label="Comments" name="contactMessage" type="input" multiline fullWidth 
                            error={this.state.contactMessage.length < 10}
                            onChange={this.handleChange}/>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="raised" 
                            disabled={!(this.props.authentication.loggedIn || this.state.contactEmailValid) || this.state.contactMessage.length < 10}
                            onClick={this.handleContactSend}>
                        Send
                        </Button>
                        <Button variant="raised" onClick={this.handleContactClose}>
                        Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.comingSoonOpen}
                    TransitionComponent={Transition}
                    onClose={this.handleComingSoonClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    >
                    <DialogTitle id="alert-dialog-slide-title">
                        {"Coming Soon!"}
                    </DialogTitle>
                    <DialogActions>
                        <Button variant="raised" onClick={this.handleComingSoonClose}>
                        OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showPassReset}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">Send password reset email to {this.state.username}?</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.submitPassReset} variant="contained">
                            OK
                        </Button>
                        <Button onClick={this.cancelPassReset} variant="contained">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showInvalidEmail}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">Please enter a valid email address.</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.okInvalidEMail} variant="contained">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <AlertDialog alert={this.props.alert}
                handleClose={this.handleErrorClose} />
                <div>
                    <PrivateRoute path="/asl/roster" component={RosterContainer} />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
      getUserFromJWT: jwt => dispatch(userActions.getUserFromJWT(jwt)),
      updateCurrentLeague: (user, leagueName, redirect) => dispatch(userActions.updateCurrentLeague(user, leagueName, redirect)),
      sendPasswordResetEmail: (email) => dispatch(passwordResetActions.sendPasswordResetEmail(email)),
      contactUs: (email, message) => dispatch(userActions.postContactUs(email, message)),
      login: (username, password) => dispatch(userActions.login(username, password)),
      clearAlert: () => dispatch(alertActions.clearAlert())
    };
  };
  
  function mapStateToProps(state) {
      const { authentication, alert } = state;
      return {
          authentication,
          alert
      };
  }
  
  const connectedHomePage = withWidth()(connect(mapStateToProps,mapDispatchToProps)(HomePage));
  export { connectedHomePage as HomePage }; 