export const eventConstants = {
    EVENTS_REQUEST: 'EVENTS_REQUEST',
    EVENTS_SUCCESS: 'EVENTS_SUCCESS',
    EVENTS_FAILURE: 'EVENTS_FAILURE',

    EVENT_RANKINGS_REQUEST: 'EVENT_RANKINGS_REQUEST',
    EVENT_RANKINGS_SUCCESS: 'EVENT_RANKINGS_SUCCESS',
    EVENT_RANKINGS_FAILURE: 'EVENT_RANKINGS_FAILURE',

    LEAGUE_EVENTS_REQUEST: 'LEAGUE_EVENTS_REQUEST',
    LEAGUE_EVENTS_SUCCESS: 'LEAGUE_EVENTS_SUCCESS',
    LEAGUE_EVENTS_FAILURE: 'LEAGUE_EVENTS_FAILURE',

    LEAGUE_EVENT_RANKINGS_REQUEST: 'LEAGUE_EVENT_RANKINGS_REQUEST',
    LEAGUE_EVENT_RANKINGS_SUCCESS: 'LEAGUE_EVENT_RANKINGS_SUCCESS',
    LEAGUE_EVENT_RANKINGS_FAILURE: 'LEAGUE_EVENT_RANKINGS_FAILURE',

    EVENT_INFO_REQUEST: 'EVENT_INFO_REQUEST',
    EVENT_INFO_SUCCESS: 'EVENT_INFO_SUCCESS',
    EVENT_INFO_FAILURE: 'EVENT_INFO_FAILURE',

    EVENT_TOP4_REQUEST: 'EVENT_TOP4_REQUEST',
    EVENT_TOP4_SUCCESS: 'EVENT_TOP4_SUCCESS',
    EVENT_TOP4_FAILURE: 'EVENT_TOP4_FAILURE'
};