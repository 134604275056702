import { sportConstants } from '../constants/sportConstants';
import { sportService } from '../services/sportService';
import { alertActions } from './alertActions';

export const sportActions = {
    getLeagueSports
};

function getLeagueSports(leagueId) {
    return dispatch => {
        dispatch(request());

        sportService.getLeagueSports(leagueId)
            .then(
                sports => { 
                    dispatch(success(sports.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: sportConstants.SPORTS_REQUEST, text: "Request Made" } }
    function success(sports) { return { type: sportConstants.SPORTS_SUCCESS, sports } }
    function failure() { return { type: sportConstants.SPORTS_FAILURE, text: "Request Failed" } }
}
