import axios from 'axios';

export const teamsService = {
    getTeams
};

function getTeams(leagueId) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'GET',
                        url: process.env.REACT_APP_BASE_URL.concat('/teams'), 
                        headers: { 'authorization': token },
                        params: {
                            leagueId : leagueId
                        }
                    });
    return res;
}