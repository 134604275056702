import { passwordResetConstants } from '../constants/passwordResetConstants';
import { alertConstants } from '../constants/alertConstants';
import { passwordResetService } from '../services/passwordResetService';
import { alertActions } from './alertActions';

export const passwordResetActions = {
    sendPasswordResetEmail,
    getPasswordReset
};

function sendPasswordResetEmail(email) {
    return dispatch => {
        dispatch(request());

        passwordResetService.sendPasswordResetEmail(email)
            .then(
                res => {
                    dispatch(success(res.data));
                    if (res.data.isError)
                        dispatch(alertActions.errorAlert(res.data.msg));
                    else
                        dispatch(alertActions.successAlert(res.data.msg));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.errorAlert(error.toString()));
                }
            );
    };

    function request() { return { type: passwordResetConstants.PASSWORD_RESET_REQUEST, text: "Request Made" } }
    function success(data) { return { type: passwordResetConstants.PASSWORD_RESET_SUCCESS, data } }
    function failure(error) { return { type: passwordResetConstants.PASSWORD_RESET_FAILURE, error } }
}

function getPasswordReset(user, token) {
    return dispatch => {
        dispatch(request());

        passwordResetService.getPasswordReset(user, token)
            .then(
                res => {
                    if (!res.data.validToken > 0) {
                        dispatch(alertActions.errorAlert(res.data.msg));
                    }
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.errorAlert(error.toString()));
                }
            );
    };

    function request() { return { type: passwordResetConstants.PASSWORD_RESET_REQUEST, text: "Request Made" } }
    function success(data) { return { type: passwordResetConstants.PASSWORD_RESET_SUCCESS, data } }
    function failure(error) { return { type: passwordResetConstants.PASSWORD_RESET_FAILURE, error } }
}