import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

export default class LeagueInfoText extends Component {
  render() {
    return (
      <div style={{display: 'flex', flexDirection: 'row', paddingTop: 5}}>
        <Typography variant={this.props.titleVariant} style={{width: 100}}>{this.props.title}</Typography>
        <Typography variant={this.props.textVariant} style={{width: 250}} align='right'>{this.props.text}</Typography>
      </div>
    )}
}


LeagueInfoText.propTypes = {
  title: PropTypes.string,
  titleVariant: PropTypes.string,
  text: PropTypes.string,
  textVariant: PropTypes.string
}