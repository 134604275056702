import React, { Component } from 'react';
import { Route, Redirect  } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { userActions } from '../../actions/userActions';
import { draftActions } from '../../actions/draftActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import { PrivateRoute } from '../../helpers/privateRoute';
import { DraftHeader } from './draftHeader'
import { DraftSelection } from './draftSelection'
import { DraftQueue } from './draftQueue'
import { DraftHistory } from './draftHistory'

class DraftContainer extends Component{

    renderDraftOptions() {
        if (!(this.props.authentication.user.currentLeague.draftStatus === "Complete")) {
            return (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Link style={{color:'black'}} to="/asl/draft/select">
                        <Button size="small">
                            Draft
                        </Button>
                    </Link>
                    <div style={{paddingRight: 10}} />
                    <Link style={{color:'black'}} to="/asl/draft/queue">
                        <Button size="small">
                            Queue
                        </Button>
                    </Link>
                    <div style={{paddingRight: 10}} />
                </div>
            )
        }
        else {
            return null;
        }
    }

    renderDraftHeader() {
        if (!(this.props.authentication.user.currentLeague.draftStatus === "Complete")) {
            return (
                <DraftHeader />
            )
        }
        else {
            return null;
        }
    }


    render() {
        return (
            <div>
                <div>
                    {this.renderDraftHeader()}
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 10}}>
                        {this.renderDraftOptions()}
                        <Link style={{color:'black'}} to="/asl/draft/history">
                            <Button size="small">
                                History
                            </Button>
                        </Link>
                    </div>
                    <Divider />
                    <PrivateRoute path="/asl/draft/select" component={DraftSelection} />
                    <PrivateRoute path="/asl/draft/queue" component={DraftQueue} />
                    <PrivateRoute path="/asl/draft/history" component={DraftHistory} />
                </div>
            </div>)
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication
    };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}

const connectedDraftContainer = connect(mapStateToProps,mapDispatchToProps)(DraftContainer);
export { connectedDraftContainer as DraftContainer }; 