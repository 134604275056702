import { teamsConstants } from '../constants/teamsConstants';

const initialState = {isLoading: true};

export function teams(state = initialState, action) {
  switch (action.type) {
    case teamsConstants.TEAMS_REQUEST:
      return initialState;
    case teamsConstants.TEAMS_SUCCESS:
      return {
        isLoading: false,
        teams: action.teams
      };
    case teamsConstants.TEAMS_FAILURE:
      return {
        isLoading: false
      };
    default:
      return state
  }
}