import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types'

export default class EventSelector extends Component {

  handleChange = name => event => {
      this.props.clickHandler(event.target.value);
  };

    render() {   
       return (
          <div>
            <TextField
              id="select-event"
              select
              helperText="Select Event"
              value={this.props.selectedIdx}
              onChange={this.handleChange('select')}
              style = {{width: 250}}
              margin="normal"
            >
                { this.props.events.map((event, idx) => (<MenuItem key={idx} value={idx}>{event.event_name}</MenuItem>)) }
            </TextField>
          </div>
      )
    }
}


EventSelector.propTypes = {
  selectedIdx: PropTypes.number,
  events: PropTypes.arrayOf(PropTypes.object),
  clickHandler: PropTypes.func
}