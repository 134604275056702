import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { userActions } from '../../actions/userActions';
import { passwordResetActions } from '../../actions/passwordResetActions';
import { alertActions } from '../../actions/alertActions';
import { Redirect  } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import logo from '../../constants/logo.png'
import AlertDialog from '../universal/alertDialog'

const divFormStyle = {
  paddingTop: '25px',
  textAlign: 'center'
};

class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        //this.props.dispatch(userActions.logout());

        this.state = {
            username: '',
            password: '',
            showPassReset: false,
            showInvalidEmail: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const jwtToken = localStorage.getItem('id_token');
        console.log('jwtToken ' + jwtToken);
        jwtToken !== null && this.props.getUserFromJWT(jwtToken);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleErrorClose = e => {
        e.preventDefault();
        this.props.clearAlert();
    }

    emailValid(value) {
        return value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    }

    handleSubmit(e) {
        e.preventDefault();

        if (this.emailValid(this.state.username)) {
            this.props.login(this.state.username, this.state.password);
        }
        else {
            this.setState({ showInvalidEmail: true });
        }
    }

    passResetClick = e => {
        e.preventDefault();
        if (this.emailValid(this.state.username)) {
            this.setState({ showPassReset: true });
        }
        else {
            this.setState({ showInvalidEmail: true });
        }
    }

    cancelPassReset = e => {
        this.setState({ showPassReset: false });
    }

    okInvalidEMail = e => {
        this.setState({ showInvalidEmail: false });
    }

    submitPassReset = e => { 
        this.setState({ showPassReset: false });
        this.props.sendPasswordResetEmail(this.state.username);
    }

  render() {

    const form = this.props.authentication.loggedIn 
                ? (<Redirect to='/asl/roster' />)
                : (<div style={divFormStyle}>
                    <img src={logo} alt='ASL'/>
                    <form onSubmit={this.handleSubmit}>
                        <div>
                            <TextField label="Email" name="username" type="email" onChange={this.handleChange}/>
                        </div>
                        <div>
                            <TextField label="Password" name="password" type="password" onChange={this.handleChange}/>
                        </div>
                        <Typography align='center' style={{paddingTop: 10}}>
                            <Button variant="raised" type="submit">Login</Button>
                        </Typography>
                    </form>
                </div>);

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            {form}
            <div style={{paddingTop: '20px'}}>
                <a style={{textDecoration: 'none'}} href="#" onClick={this.passResetClick}>
                    <Typography variant='caption'>Forgot Password</Typography>
                </a>
            </div>
            <AlertDialog alert={this.props.alert}
                handleClose={this.handleErrorClose} />
            <div>
                <Dialog
                open={this.state.showPassReset}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">Send password reset email to {this.state.username}?</DialogTitle>
                <DialogActions>
                    <Button onClick={this.submitPassReset} variant="contained">
                        OK
                    </Button>
                    <Button onClick={this.cancelPassReset} variant="contained">
                        Cancel
                    </Button>
                </DialogActions>
                </Dialog>
            </div>
            <div>
                <Dialog
                open={this.state.showInvalidEmail}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">Please enter a valid email address.</DialogTitle>
                <DialogActions>
                    <Button onClick={this.okInvalidEMail} variant="contained">
                        OK
                    </Button>
                </DialogActions>
                </Dialog>
            </div>
        </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
    return {
      login: (username, password) => dispatch(userActions.login(username, password)),
      getUserFromJWT: (jwtToken) =>  dispatch(userActions.getUserFromJWT(jwtToken)),
      sendPasswordResetEmail: (email) => dispatch(passwordResetActions.sendPasswordResetEmail(email)),
      clearAlert: () => dispatch(alertActions.clearAlert())
    };
  };

function mapStateToProps(state) {
    const { authentication, alert } = state;
    return {
        authentication,
        alert
    };
}

const connectedLoginForm = connect(mapStateToProps,mapDispatchToProps)(LoginForm);
export { connectedLoginForm as LoginForm }; 
 