import { commishNotesConstants } from '../constants/commishNotesConstants';

const initialState = {isLoading: true};

export function commishNotes(state = initialState, action) {
  switch (action.type) {
    case commishNotesConstants.COMMISH_NOTES_REQUEST:
      return initialState;
    case commishNotesConstants.COMMISH_NOTES_SUCCESS:
      return {
        isLoading: false,
        commishNotes: action.commishNotes
      };
    case commishNotesConstants.COMMISH_NOTES_FAILURE:
      return {
        isLoading: false,
      };
    default:
      return state
  }
}