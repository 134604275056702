import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect  } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { draftActions } from '../../actions/draftActions';
import { eventActions } from '../../actions/eventActions';
import { sportActions } from '../../actions/sportActions';
import { rosterActions } from '../../actions/rosterActions';
import Spinner from '../universal/spinner';

class DraftSelection extends Component{

    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: false,
            selectedParticipantId: null,
            selectedEventId: null,
            selectedMessage: null
        };
    }

    componentDidMount() {
        if (!(this.props.authentication.user.currentLeague.draftStatus === "Complete")) {
          const leagueId = this.props.authentication.user.currentLeague.leagueId;
          const userId = this.props.authentication.user.id;
          if (this.props.draftSelection.draftSelection) {
            this.props.getDraftSelection(leagueId, userId, 
                                  this.props.draftSelection.draftSelection.filters.eventName, 
                                  this.props.draftSelection.draftSelection.filters.sportName, 
                                  this.props.draftSelection.draftSelection.filters.limit); 
          }
          else {
            this.props.getDraftSelection(leagueId, userId, 'ALL-', 'ALL', 200);  
          }
          this.props.getLeagueEvents(leagueId);
          this.props.getLeagueSports(leagueId);
          this.props.getDraftUser(leagueId, userId);
        }
    }

    componentDidUpdate(prevProps) {
      // Typical usage (don't forget to compare props):
      if (!this.props.draftSelection.isLoading && prevProps.draftSelection.isLoading) {
          const leagueId = this.props.authentication.user.currentLeague.leagueId;
          const userId = this.props.authentication.user.id
          this.props.getRoster(leagueId, userId)
          this.props.getDraftUser(leagueId, userId);
      }
    }

  handleListItemClick = evt => {
    const things = evt.currentTarget.id.split(";");
    this.setState({ 
        dialogOpen: true,
        selectedParticipantId: things[0],
        selectedEventId: things[1],
        selectedMessage: things[2]
    });
  };

  handleClose = value => {
    this.setState({ 
        dialogOpen: false,
        selectedParticipantId: null,
        selectedEventId: null,
        selectedMessage: null
    });
  };

  handleDraft = value => {
    const filters = {
      eventName: this.props.draftSelection.draftSelection.filters.eventName,
      sportName: this.props.draftSelection.draftSelection.filters.sportName,
      limit: this.props.draftSelection.draftSelection.filters.limit
    }
    this.props.updateDraftSelection(this.props.authentication.user.currentLeague.leagueId, 
                                  this.props.authentication.user.id, 
                                  this.state.selectedEventId, 
                                  this.state.selectedParticipantId, 
                                  filters); 
    this.handleClose();
  };

  handleQueue = value => {
    this.props.insertDraftQueue(this.props.authentication.user.currentLeague.leagueId, 
                                  this.props.authentication.user.id, 
                                  this.state.selectedEventId, 
                                  this.state.selectedParticipantId); 
    this.handleClose();
  };

  handleAutoDraftChange = value => {
    this.props.updateDraftUser(this.props.authentication.user.currentLeague.leagueId, 
                                this.props.authentication.user.id, 
                                value.target.value);  
  };

  handleLimitChange = value => {
    this.props.getDraftSelection(this.props.authentication.user.currentLeague.leagueId, 
                                this.props.authentication.user.id,
                                this.props.draftSelection.draftSelection.filters.eventName, 
                                this.props.draftSelection.draftSelection.filters.sportName, 
                                value.target.value);  
  };

  handleEventChange = value => {
    this.props.getDraftSelection(this.props.authentication.user.currentLeague.leagueId,
                                this.props.authentication.user.id, 
                                value.target.value, 
                                this.props.draftSelection.draftSelection.filters.sportName, 
                                this.props.draftSelection.draftSelection.filters.limit); 
  };

  handleSportChange = value => {
    this.props.getDraftSelection(this.props.authentication.user.currentLeague.leagueId, 
                                this.props.authentication.user.id,
                                this.props.draftSelection.draftSelection.filters.eventName,
                                value.target.value,
                                this.props.draftSelection.draftSelection.filters.limit); 
  };

    renderAutoDraftMessage() {
      if (this.props.draftUser.draftUser.autoDrafting === 1) {
        return (
            <Typography variant="subtitle1" color="secondary">You are currently auto drafting. When it is your turn the highest player, 
                                  or team, in your queue will be choosen. If the queue is empty then the higest 
                                  available overall selection will be made. </Typography>
          )
      }
      else {
        return null
      }
    }

    renderNoResults() {
      if (this.props.draftSelection.draftSelection.participants.length === 0) {
        return (
            <Typography style={{paddingTop: 25}} variant="subtitle1" color="secondary">No results, change filter criteria. </Typography>
          )
      }
      else {
        return null
      }
    }

    renderSelections() {
        if (this.props.authentication.user.currentLeague.draftStatus !== "Drafting") {
          return (<Redirect to='/asl/draft/history' />)
        }
        else if (!this.props.draftSelection.isLoading && !this.props.leagueEvents.isLoading && !this.props.leagueSports.isLoading && !this.props.draftUser.isLoading) {
            return (
                <div style={{width: 350, alignContent: 'center'}}>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="subheading">Filters</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div>
                        <FormControl style={{width: 140}}>
                          <Select
                            value={this.props.draftSelection.draftSelection.filters.limit}
                            onChange={this.handleLimitChange}
                            inputProps={{
                              name: 'limit',
                              id: 'limit-simple',
                            }}
                          >
                            <MenuItem value='50'>50</MenuItem>
                            <MenuItem value='200'>200</MenuItem>
                            <MenuItem value='400'>400</MenuItem>
                            <MenuItem value='2000'><em>None</em></MenuItem>
                          </Select>
                      </FormControl>
                      <FormControl style={{width: 140, paddingLeft: 20}}>
                          <Select
                            value={this.props.draftUser.draftUser.autoDrafting}
                            onChange={this.handleAutoDraftChange}
                            inputProps={{
                              name: 'autoDraft',
                              id: 'auto-simple',
                            }}
                          >
                            <MenuItem value={1}>On</MenuItem>
                            <MenuItem value={0}>Off</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl style={{width: 140}}>
                          <Typography variant="caption">Top</Typography>
                        </FormControl>
                        <FormControl style={{width: 140, paddingLeft: 20}}>
                          <Typography variant="caption">Auto Draft</Typography>
                        </FormControl>
                        <FormControl style={{width: 280, paddingTop: 10}}>
                          <Select
                            value={this.props.draftSelection.draftSelection.filters.eventName}
                            onChange={this.handleEventChange}
                            inputProps={{
                              name: 'eventSelector',
                              id: 'event-simple',
                            }}
                          >
                          <MenuItem key='ALL-' value='ALL-'>ALL</MenuItem>
                          <MenuItem key='ALL-draftable' value='ALL-draftable'>DRAFTABLE ONLY</MenuItem>
                          {this.props.leagueEvents.events.map((event) => (
                              <MenuItem disabled={this.props.draftUser.draftUser.closedEvents.split(',').includes(event.eventDateId.toString())} key={event.eventName} value={event.eventName}>{event.eventName}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl style={{width: 140}}>
                          <Typography variant="caption">Event</Typography>
                        </FormControl>
                        <FormControl style={{width: 280, paddingTop: 10}}>
                          <Select
                            value={this.props.draftSelection.draftSelection.filters.sportName}
                            onChange={this.handleSportChange}
                            inputProps={{
                              name: 'sportSelector',
                              id: 'sport-simple',
                            }}
                          >
                          <MenuItem key='ALL' value='ALL'>ALL</MenuItem>
                          {this.props.leagueSports.sports.map((sport) => (
                              <MenuItem key={sport.sportName} value={sport.sportName}>{sport.sportName}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl style={{width: 140}}>
                          <Typography variant="caption">Sport</Typography>
                        </FormControl>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  {this.renderAutoDraftMessage()}
                  {this.renderNoResults()}
                    <List>
                        {this.props.draftSelection.draftSelection.participants.map((draftOption) => (
                            <ListItem key={draftOption.participantName + ' for ' + draftOption.eventName} 
                                    id={draftOption.participantId + ';' + draftOption.eventDateId + ';' + draftOption.participantName + ' for ' + draftOption.eventName} 
                                    divider 
                                    button 
                                    disabled={this.props.draftUser.draftUser.closedEvents.split(',').includes(draftOption.eventDateId.toString())}
                                    onClick={(evt) => this.handleListItemClick(evt)}>
                                <ListItemText primary={draftOption.participantName} 
                                    secondary={draftOption.eventName + ' | ' + draftOption.eventOdds}  />
                            </ListItem>
                        ))}
                    </List>
                    <div>
                        <Dialog
                          open={this.state.dialogOpen}
                          onClose={this.handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{this.state.selectedMessage}</DialogTitle>
                          <DialogActions>
                            <Button onClick={this.handleDraft} color="primary" disabled={this.props.draftUser.draftUser.onClock === 0 || this.props.draftUser.draftUser.autoDrafting === 1 ? true : false}>
                              Draft
                            </Button>
                            <Button onClick={this.handleQueue} color="primary" autoFocus>
                              Queue
                            </Button>
                          </DialogActions>
                        </Dialog>
                    </div>
                </div>
            )
        }
        else {
            return (<div><Spinner/></div>);
        }
    }

    render() {
        return (
            <div style={{display:'flex', flexDirection: 'row', justifyContent: 'center'}}>
                {this.renderSelections()}
            </div>)
    }
}

function mapStateToProps(state) {
    const { authentication, draftSelection, leagueEvents, leagueSports, draftUser, draftOrder } = state;
    return {
        authentication,
        draftSelection,
        leagueEvents,
        leagueSports,
        draftUser,
        draftOrder
    };
}

const mapDispatchToProps = dispatch => {
  return {
    getDraftSelection: (leagueId, userId, eventName, sportName, limit) => dispatch(draftActions.getDraftSelection(leagueId, userId, eventName, sportName, limit)),
    getLeagueEvents: (leagueId) => dispatch(eventActions.getLeagueEvents(leagueId)),
    getLeagueSports: (leagueId) => dispatch(sportActions.getLeagueSports(leagueId)),
    getDraftUser: (leagueId, userId) => dispatch(draftActions.getDraftUser(leagueId, userId)),
    updateDraftUser: (leagueId, userId, autoDraft) => dispatch(draftActions.updateDraftUser(leagueId, userId, autoDraft)),
    updateDraftSelection: (leagueId, userId, eventId, participantId, filters) => dispatch(draftActions.updateDraftSelection(leagueId, userId, eventId, participantId, filters)),
    getRoster: (leagueId, userId) => dispatch(rosterActions.getRoster(leagueId, userId)),
    insertDraftQueue: (leagueId, userId, eventId, participantId) => dispatch(draftActions.insertDraftQueue(leagueId, userId, eventId, participantId))
  };
}

const connectedDraftSelection = connect(mapStateToProps,mapDispatchToProps)(DraftSelection);
export { connectedDraftSelection as DraftSelection }; 