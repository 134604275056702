import { createMuiTheme } from '@material-ui/core/styles';

const customTable = createMuiTheme({
    overrides:{
        MuiTableRow: {
            root: {
                height: '42px'
              },
            head: {
                height: '48px'
            }
        },
        MuiTableCell: {
            body: {
                fontSize: '1rem'
            },
            head: {
                fontSize: '1rem'
            }
        }
    }
});
export default customTable