import { createMuiTheme } from '@material-ui/core/styles';

const customBadgeRankDown = createMuiTheme({
    overrides:{
        MuiBadge: {
            badge: {
                top: '-4px'
            }
        },
        MuiSvgIcon: {
            root: {
                color: 'green'
              },
        },
    }
});
export default customBadgeRankDown;