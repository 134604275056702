import { processRankingsConstants } from '../../constants/admin/processRankingsConstants';
import { processRankingsService } from '../../services/admin/processRankingsService';
import { alertActions } from '../alertActions';

export const processRankingsActions = {
    previewRankings,
    rankingsMatch,
    rankingsAdd,
    rankingsInclude,
    rankingsSubmit
};

function previewRankings(eventDateId) {
    return dispatch => {
        dispatch(request());

        processRankingsService.previewRankings(eventDateId)
            .then(
                result => { 
                    dispatch(success(result.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: processRankingsConstants.PROCESS_RANKINGS_REQUEST, text: "Request Made" } }
    function success(processRankings) { return { type: processRankingsConstants.PROCESS_RANKINGS_SUCCESS, processRankings } }
    function failure() { return { type: processRankingsConstants.PROCESS_RANKINGS_FAILURE, text: "Request Failed" } }
}

function rankingsMatch(eventDateId, participantName, participantIdTo) {
    return dispatch => {
        dispatch(request());

        processRankingsService.rankingsMatch(eventDateId, participantName, participantIdTo)
            .then(
                result => { 
                    dispatch(success(result.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: processRankingsConstants.PROCESS_RANKINGS_REQUEST, text: "Request Made" } }
    function success(processRankings) { return { type: processRankingsConstants.PROCESS_RANKINGS_SUCCESS, processRankings } }
    function failure() { return { type: processRankingsConstants.PROCESS_RANKINGS_FAILURE, text: "Request Failed" } }
}

function rankingsAdd(eventDateId, participantName) {
    return dispatch => {
        dispatch(request());

        processRankingsService.rankingsAdd(eventDateId, participantName)
            .then(
                result => { 
                    dispatch(success(result.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: processRankingsConstants.PROCESS_RANKINGS_REQUEST, text: "Request Made" } }
    function success(processRankings) { return { type: processRankingsConstants.PROCESS_RANKINGS_SUCCESS, processRankings } }
    function failure() { return { type: processRankingsConstants.PROCESS_RANKINGS_FAILURE, text: "Request Failed" } }
}

function rankingsInclude(eventDateId, participantId) {
    return dispatch => {
        dispatch(request());

        processRankingsService.rankingsInclude(eventDateId, participantId)
            .then(
                result => { 
                    dispatch(success(result.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: processRankingsConstants.PROCESS_RANKINGS_REQUEST, text: "Request Made" } }
    function success(processRankings) { return { type: processRankingsConstants.PROCESS_RANKINGS_SUCCESS, processRankings } }
    function failure() { return { type: processRankingsConstants.PROCESS_RANKINGS_FAILURE, text: "Request Failed" } }
}

function rankingsSubmit(eventDateId, complete) {
    return dispatch => {
        dispatch(request());

        processRankingsService.rankingsSubmit(eventDateId, complete)
            .then(
                result => { 
                    dispatch(success(result.data));
                    dispatch(alertActions.errorAlert('Success!'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: processRankingsConstants.PROCESS_RANKINGS_REQUEST, text: "Request Made" } }
    function success(processRankings) { return { type: processRankingsConstants.PROCESS_RANKINGS_SUCCESS, processRankings } }
    function failure() { return { type: processRankingsConstants.PROCESS_RANKINGS_FAILURE, text: "Request Failed" } }
}