import { createMuiTheme } from '@material-ui/core/styles';

const customAppBar = createMuiTheme({
    overrides:{
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: '#21618C'
            }
        }
    }
});
export default customAppBar;