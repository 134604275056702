import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { standingsActions } from '../../actions/standingsActions';
import { connect } from 'react-redux';
import Spinner from '../universal/spinner';
import { MuiThemeProvider } from '@material-ui/core/styles';
import customBadgeRankUp from '../../styles/themes/customBadgeRankUp';
import customBadgeRankDown from '../../styles/themes/customBadgeRankDown';
import UpArrow from '@material-ui/icons/ArrowDropUp';
import DownArrow from '@material-ui/icons/ArrowDropDown';
import Badge from '@material-ui/core/Badge';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import customTable from '../../styles/themes/customTable'

export default class StandingsTable extends Component {

    componentDidMount() {
        this.props.getStandings(this.props.authentication.user.currentLeague.leagueId);
    }

    componentDidUpdate(prevProps) {
      // Typical usage (don't forget to compare props):
      var leagueId = this.props.authentication.user.currentLeague.leagueId
      if (leagueId !== prevProps.authentication.user.currentLeague.leagueId) {
        this.props.getStandings(leagueId);
      }
    }

    loadData(leagueId) {
      this.props.getStandings(leagueId);  
    }

    renderChange(change) {
      if (change == 0) {
        return null
      }
      else if (change < 0) {
        return (<MuiThemeProvider theme={customBadgeRankDown}>
          <Badge badgeContent={Math.abs(change)}><DownArrow/></Badge>
        </MuiThemeProvider>)
      }
      else {
        return (<MuiThemeProvider theme={customBadgeRankUp}>
          <Badge badgeContent={change}><UpArrow/></Badge>
        </MuiThemeProvider>)
      }
    }

    renderStandings() {
        if(!this.props.standings.isLoading) {
            return (
              <Paper>
                <MuiThemeProvider theme ={customTable}>
                  <Table fixedHeader={false} style={{tableLayout: "auto" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{width: '55%'}}>Team</TableCell>
                        <TableCell numeric style={{width: '15%'}}>Points</TableCell>
                        <TableCell numeric style={{width: '15%'}}>Power Rank</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.standings.teams.map(team => {
                        return (
                          <TableRow key={team.team}>
                            <TableCell>{team.team}{this.renderChange(team.change)}</TableCell>
                            <TableCell numeric>{team.pts}</TableCell>
                            <TableCell numeric>{team.powerRank}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </MuiThemeProvider>
              </Paper>
            );
        } else {
        return (<div><Spinner/></div>);
        }
    };

    render() { 

        const d1Style = {
          display: 'flex',
          flexDirection: 'row',
          paddingTop: '38px',
          justifyContent: 'center'
        };

         return (
            <div style={d1Style}>
              {this.renderStandings()}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
  return {
    getStandings: (leagueId) => dispatch(standingsActions.getStandings(leagueId))
  };
};

function mapStateToProps(state) {
    const { authentication, standings } = state;
    return {
        authentication,
        standings
    };
}

const connectedStandingsTable = connect(mapStateToProps,mapDispatchToProps)(StandingsTable);
export { connectedStandingsTable as StandingsTable }; 