import { draftConstants } from '../constants/draftConstants';
import { draftService } from '../services/draftService';
import { alertActions } from './alertActions';

export const draftActions = {
    getDraftOrder,
    getDraftSelection,
    updateDraftSelection,
    getDraftUser,
    updateDraftUser,
    getDraftQueue,
    insertDraftQueue,
    deleteDraftQueue,
    updateDraftQueue,
    getDraftHistory
};

function getDraftOrder(leagueId, init) {
    return dispatch => {
        if (init) {
            dispatch(request());
        }

        draftService.getDraftOrder(leagueId)
            .then(
                draftOrder => { 
                    dispatch(success(draftOrder.data));
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: draftConstants.DRAFT_ORDER_REQUEST, text: "Request Made" } }
    function success(draftOrder) { return { type: draftConstants.DRAFT_ORDER_SUCCESS, draftOrder } }
    function failure() { return { type: draftConstants.DRAFT_ORDER_FAILURE, text: "Request Failed" } }
}

function getDraftSelection(leagueId, userId, eventName, sportName, limit) {
    return dispatch => {
        const filters = {leagueId: leagueId, eventName: eventName, sportName: sportName, limit: limit}
        dispatch(request(filters));

        draftService.getDraftSelection(leagueId, userId, eventName, sportName, limit)
            .then(
                draftSelection => { 
                    dispatch(success(draftSelection.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(filters) { return { type: draftConstants.DRAFT_SELECTION_REQUEST, filters: filters } }
    function success(draftSelection) { return { type: draftConstants.DRAFT_SELECTION_SUCCESS, draftSelection } }
    function failure() { return { type: draftConstants.DRAFT_SELECTION_FAILURE, text: "Request Failed" } }
}

function updateDraftSelection(leagueId, userId, eventId, participantId, filters) {
    return dispatch => {
        dispatch(request());

        draftService.updateDraftSelection(leagueId, userId, eventId, participantId, filters)
            .then(
                draftSelection => { 
                    dispatch(success(draftSelection.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: draftConstants.DRAFT_SELECTION_REQUEST, text: "Request Made" } }
    function success(draftSelection) { return { type: draftConstants.DRAFT_SELECTION_SUCCESS, draftSelection } }
    function failure() { return { type: draftConstants.DRAFT_SELECTION_FAILURE, text: "Request Failed" } }
}

function getDraftUser(leagueId, userId) {
    return dispatch => {
        dispatch(request());

        draftService.getDraftUser(leagueId, userId)
            .then(
                draftUser => { 
                    dispatch(success(draftUser.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: draftConstants.DRAFT_USER_REQUEST, text: "Request Made" } }
    function success(draftUser) { return { type: draftConstants.DRAFT_USER_SUCCESS, draftUser } }
    function failure() { return { type: draftConstants.DRAFT_USER_FAILURE, text: "Request Failed" } }
}

function updateDraftUser(leagueId, userId, autoDraft) {
    return dispatch => {
        dispatch(request());

        draftService.updateDraftUser(leagueId, userId, autoDraft)
            .then(
                draftUser => { 
                    dispatch(success(draftUser.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: draftConstants.DRAFT_USER_REQUEST, text: "Request Made" } }
    function success(draftUser) { return { type: draftConstants.DRAFT_USER_SUCCESS, draftUser } }
    function failure() { return { type: draftConstants.DRAFT_USER_FAILURE, text: "Request Failed" } }
}

function getDraftQueue(leagueId, userId) {
    return dispatch => {
        dispatch(request());

        draftService.getDraftQueue(leagueId, userId)
            .then(
                draftQueue => { 
                    dispatch(success(draftQueue.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: draftConstants.DRAFT_QUEUE_REQUEST, text: "Request Made" } }
    function success(draftQueue) { return { type: draftConstants.DRAFT_QUEUE_SUCCESS, draftQueue } }
    function failure() { return { type: draftConstants.DRAFT_QUEUE_FAILURE, text: "Request Failed" } }
}

function insertDraftQueue(leagueId, userId, eventDateId, participantId) {
    return dispatch => {
        dispatch(request());

        draftService.insertDraftQueue(leagueId, userId, eventDateId, participantId)
            .then(
                draftQueue => { 
                    dispatch(success(draftQueue.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: draftConstants.DRAFT_QUEUE_REQUEST, text: "Request Made" } }
    function success(draftQueue) { return { type: draftConstants.DRAFT_QUEUE_SUCCESS, draftQueue } }
    function failure() { return { type: draftConstants.DRAFT_QUEUE_FAILURE, text: "Request Failed" } }
}

function deleteDraftQueue(leagueId, userId, eventDateId, participantId) {
    return dispatch => {
        dispatch(request());

        draftService.deleteDraftQueue(leagueId, userId, eventDateId, participantId)
            .then(
                draftQueue => { 
                    dispatch(success(draftQueue.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: draftConstants.DRAFT_QUEUE_REQUEST, text: "Request Made" } }
    function success(draftQueue) { return { type: draftConstants.DRAFT_QUEUE_SUCCESS, draftQueue } }
    function failure() { return { type: draftConstants.DRAFT_QUEUE_FAILURE, text: "Request Failed" } }
}

function updateDraftQueue(leagueId, userId, eventDateId, participantId, direction) {
    return dispatch => {
        dispatch(request());

        draftService.updateDraftQueue(leagueId, userId, eventDateId, participantId, direction)
            .then(
                draftQueue => { 
                    dispatch(success(draftQueue.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: draftConstants.DRAFT_QUEUE_REQUEST, text: "Request Made" } }
    function success(draftQueue) { return { type: draftConstants.DRAFT_QUEUE_SUCCESS, draftQueue } }
    function failure() { return { type: draftConstants.DRAFT_QUEUE_FAILURE, text: "Request Failed" } }
}

function getDraftHistory(leagueId) {
    return dispatch => {
        dispatch(request());

        draftService.getDraftHistory(leagueId)
            .then(
                draftHistory => { 
                    dispatch(success(draftHistory.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: draftConstants.DRAFT_HISTORY_REQUEST, text: "Request Made" } }
    function success(draftHistory) { return { type: draftConstants.DRAFT_HISTORY_SUCCESS, draftHistory } }
    function failure() { return { type: draftConstants.DRAFT_HISTORY_FAILURE, text: "Request Failed" } }
}

