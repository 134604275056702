import { eventConstants } from '../constants/eventConstants';
import { eventService } from '../services/eventService';
import { alertActions } from './alertActions';

export const eventActions = {
    getEvents,
    getEventRankings,
    getLeagueEvents,
    getLeagueEventRankings,
    getEventInfo,
    updateEventTop4,
    updateEventTop4Pts,
    updateEventTie,
    updateEventComplete,
    updateEventDate,
    saveEventInfo
};

function getEvents() {
    return dispatch => {
        dispatch(request());

        eventService.getEvents()
            .then(
                events => { 
                    dispatch(success(events.data));
                    if (events.data.length > 0) {
                        dispatch(getEventRankings(events.data[0].event_date_id));
                    }

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: eventConstants.EVENTS_REQUEST, text: "Request Made" } }
    function success(events) { return { type: eventConstants.EVENTS_SUCCESS, events } }
    function failure() { return { type: eventConstants.EVENTS_FAILURE, text: "Request Failed" } }
}

function getEventRankings(eventDateId) {
    return dispatch => {
        dispatch(request());

        eventService.getEventRankings(eventDateId)
            .then(
                eventRankings => { 
                    dispatch(success(eventRankings.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.errorAlert(error.toString()));
                }
            );
    };

    function request() { return { type: eventConstants.EVENT_RANKINGS_REQUEST, text: "Request Made" } }
    function success(eventRankings) { return { type: eventConstants.EVENT_RANKINGS_SUCCESS, eventRankings } }
    function failure() { return { type: eventConstants.EVENT_RANKINGS_FAILURE, text: "Request Failed" } }
}

function getLeagueEvents(leagueId) {
    return dispatch => {
        dispatch(request());

        eventService.getLeagueEvents(leagueId)
            .then(
                leagueEvents => { 
                    dispatch(success(leagueEvents.data));
                    if (leagueEvents.data.leagueEvents.length > 0) {
                        dispatch(getLeagueEventRankings(leagueEvents.data.leagueId, leagueEvents.data.leagueEvents[0].eventDateId));
                    }

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: eventConstants.LEAGUE_EVENTS_REQUEST, text: "Request Made" } }
    function success(leagueEvents) { return { type: eventConstants.LEAGUE_EVENTS_SUCCESS, leagueEvents } }
    function failure() { return { type: eventConstants.LEAGUE_EVENTS_FAILURE, text: "Request Failed" } }
}

function getLeagueEventRankings(leagueId, eventDateId) {
    return dispatch => {
        dispatch(request());

        eventService.getLeagueEventRankings(leagueId, eventDateId)
            .then(
                eventRankings => { 
                    dispatch(success(eventRankings.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.errorAlert(error.toString()));
                }
            );
    };

    function request() { return { type: eventConstants.LEAGUE_EVENT_RANKINGS_REQUEST, text: "Request Made" } }
    function success(eventRankings) { return { type: eventConstants.LEAGUE_EVENT_RANKINGS_SUCCESS, eventRankings } }
    function failure() { return { type: eventConstants.LEAGUE_EVENT_RANKINGS_FAILURE, text: "Request Failed" } }
}

function getEventInfo(eventName) {
    return dispatch => {
        dispatch(request({ eventName }));

        eventService.getEventInfo(eventName)
            .then(
                eventInfo => { 
                    dispatch(updateEventTop4(eventInfo.data.participants));
                    dispatch(success(eventInfo.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(eventName) { return { type: eventConstants.EVENT_INFO_REQUEST, eventName } }
    function success(eventInfo) { return { type: eventConstants.EVENT_INFO_SUCCESS, eventInfo } }
    function failure(eventName) { return { type: eventConstants.EVENT_INFO_FAILURE, eventName } }
}

function updateEventTop4(participants) {
    return dispatch => {
        dispatch(request());

        dispatch(success(eventService.updateEventTop4(participants)));
    };

    function request(eventName) { return { type: eventConstants.EVENT_TOP4_REQUEST } }
    function success(top4) { return { type: eventConstants.EVENT_TOP4_SUCCESS, top4 } }
    //function failure(eventName) { return { type: eventConstants.EVENT_TOP4_FAILURE } }
}

function updateEventTop4Pts(top4,idx,pts) {
    return dispatch => {
        dispatch(request());

        dispatch(success(eventService.updateEventTop4Pts(top4,idx,pts)));
    };

    function request(eventName) { return { type: eventConstants.EVENT_TOP4_REQUEST } }
    function success(top4) { return { type: eventConstants.EVENT_TOP4_SUCCESS, top4 } }
    //function failure(eventName) { return { type: eventConstants.EVENT_TOP4_FAILURE } }
}

function updateEventTie(eventInfo, index, checked) {
    return dispatch => {
        dispatch(request());

        dispatch(success(eventService.updateEventTie(eventInfo, index, checked)));
    };

    function request(eventName) { return { type: eventConstants.EVENT_TOP4_REQUEST } }
    function success(top4) { return { type: eventConstants.EVENT_TOP4_SUCCESS, top4 } }
    //function failure(eventName) { return { type: eventConstants.EVENT_TOP4_FAILURE } }
}

function updateEventComplete(eventInfo) {
    return dispatch => {
        dispatch(request());

        dispatch(success(eventService.updateEventComplete(eventInfo)));
    };

    function request(eventName) { return { type: eventConstants.EVENT_INFO_REQUEST } }
    function success(eventInfo) { return { type: eventConstants.EVENT_INFO_SUCCESS, eventInfo } }
    //function failure(eventName) { return { type: eventConstants.EVENT_INFO_FAILURE } }
}

function updateEventDate(eventInfo, date) {
    return dispatch => {
        dispatch(request());

        dispatch(success(eventService.updateEventDate(eventInfo, date)));
    };

    function request(eventName) { return { type: eventConstants.EVENT_INFO_REQUEST } }
    function success(eventInfo) { return { type: eventConstants.EVENT_INFO_SUCCESS, eventInfo } }
    //function failure(eventName) { return { type: eventConstants.EVENT_INFO_FAILURE } }
}

function saveEventInfo(eventInfo, eventTop4) {
    return dispatch => {
        dispatch(request());

        eventService.saveEventInfo(eventInfo, eventTop4)
            .then(
                eventInfo => { 
                    dispatch(updateEventTop4(eventInfo.data.participants));
                    dispatch(success(eventInfo.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(eventName) { return { type: eventConstants.EVENT_INFO_REQUEST } }
    function success(eventInfo) { return { type: eventConstants.EVENT_INFO_SUCCESS, eventInfo } }
    function failure(eventName) { return { type: eventConstants.EVENT_INFO_FAILURE } }
}


