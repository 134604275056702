import { alertConstants } from '../constants/alertConstants';

//let user = JSON.parse(localStorage.getItem('id_token'));
//const initialState = user ? { loggedIn: true, user } : {loggedIn: false};

const initialState = {showAlert: false};

export function alert(state = initialState, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        showAlert: true,
        title: 'SUCCESS',
        messageType: 'SUCCESS',
        message: action.message
      };
    case alertConstants.ERROR:
      return {
        showAlert: true,
        title: 'ERROR',
        messageType: 'ERROR',
        message: action.message
      };
    case alertConstants.CLEAR:
      return {
        showAlert: false
      };
    default:
      return state;
  }
}