import { draftConstants } from '../constants/draftConstants';

const initialState = {isLoading: true};

export function draftQueue(state = initialState, action) {
  switch (action.type) {
    case draftConstants.DRAFT_QUEUE_REQUEST:
      return initialState;
    case draftConstants.DRAFT_QUEUE_SUCCESS:
      return {
        isLoading: false,
        draftQueue: action.draftQueue
      };
    case draftConstants.DRAFT_QUEUE_FAILURE:
      return {
        isLoading: false,
      };
    default:
      return state
  }
}