import { alertConstants } from '../constants/alertConstants';

export const alertActions = {
    successAlert,
    errorAlert,
    clearAlert
};

function successAlert(message) {
    return { type: alertConstants.SUCCESS, message: message };
}

function errorAlert(error) {
    var message = error.response && error.response.data && error.response.data.error ? error.response.data.error : error.toString();
    return { type: alertConstants.ERROR, message: message };
}

function clearAlert() {
    return { type: alertConstants.CLEAR };
}