import { eventConstants } from '../constants/eventConstants';

const initialState = {isLoading: true};

export function eventInfo(state = initialState, action) {
  switch (action.type) {
    case eventConstants.EVENT_INFO_REQUEST:
      return initialState;
    case eventConstants.EVENT_INFO_SUCCESS:
      return {
        isLoading: false,
        eventInfo: action.eventInfo.eventInfo,
        participants: action.eventInfo.participants
      };
    case eventConstants.EVENT_INFO_FAILURE:
      return {
        isLoading: false,
        eventInfo: {},
        participants: {}
      };
    default:
      return state
  }
}