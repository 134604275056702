import { teamsConstants } from '../constants/teamsConstants';
import { teamsService } from '../services/teamsService';
import { alertActions } from './alertActions';

export const teamsActions = {
    getTeams
};

function getTeams(leagueId) {
    return dispatch => {
        dispatch(request());

        teamsService.getTeams(leagueId)
            .then(
                teams => { 
                    dispatch(success(teams.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: teamsConstants.TEAMS_REQUEST, text: "Request Made" } }
    function success(teams) { return { type: teamsConstants.TEAMS_SUCCESS, teams } }
    function failure() { return { type: teamsConstants.TEAMS_FAILURE, text: "Request Failed" } }
}