import { rosterConstants } from '../constants/rosterConstants';

const initialState = {isLoading: true};

export function roster(state = initialState, action) {
  switch (action.type) {
    case rosterConstants.ROSTER_REQUEST:
      return initialState;
    case rosterConstants.ROSTER_SUCCESS:
      return {
        isLoading: false,
        roster: action.roster
      };
    case rosterConstants.ROSTER_FAILURE:
      return {
        isLoading: false,
        roster: {}
      };
    default:
      return state
  }
}