export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LEAGUE_REQUEST: 'USERS_LEAGUE_REQUEST',
    LEAGUE_SUCCESS: 'USERS_LEAGUE_SUCCESS',
    LEAGUE_SUCCESS_REDIRECT: 'USERS_LEAGUE_SUCCESS_REDIRECT',
    LEAGUE_FAILURE: 'USERS_LEAGUE_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    LOGOUT: 'USERS_LOGOUT'
};