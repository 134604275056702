import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types'

import { MuiThemeProvider } from '@material-ui/core/styles';
import customDisabledTextInput from '../../styles/themes/customDisabledTextInput'

export default class EventSelector extends Component {

    constructor(props) {
        super(props);
        const firstEvent = this.props.events.filter(event => event.eventDateId === this.props.firstEventDateId)[0];

        this.state = {
            selected: {id: this.props.firstEventDateId,
                      desc: firstEvent.eventName,
                      date: firstEvent.eventDate}
        };
    }


  handleChange = name => e => {
    if(e.target.value && e.target.value !== this.state.selected.id) {
      const selectedEvent = this.props.events.filter(event => event.eventDateId === e.target.value)[0];
      this.setState({
          selected: {id: selectedEvent.eventDateId,
                    desc: selectedEvent.eventName,
                    date: selectedEvent.eventDate}
      });
      this.props.getLeagueEventRankings(this.props.leagueId, e.target.value);
    }
  };

    render() {   
       return (
          <div>
            <TextField
              id="select-event"
              select
              helperText="Select Event"
              value={this.state.selected.id}
              onChange={this.handleChange('select')}
              style = {{width: 175}}
              margin="normal"
            >
                { this.props.events.map(event => (<MenuItem key={event.eventDateId} value={event.eventDateId}>{event.eventName}</MenuItem>)) }
            </TextField>
            <MuiThemeProvider theme={customDisabledTextInput}>
              <TextField
                  id="event_date"
                  disabled
                  helperText="Event Date"
                  value={this.state.selected.date}
                  style = {{width: 120, paddingLeft: 30}}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
              }}
              />
            </MuiThemeProvider>
          </div>
      )
    }
}


EventSelector.propTypes = {
  firstEventDateId: PropTypes.string,
  leagueId: PropTypes.number,
  events: PropTypes.arrayOf(PropTypes.object),
  getLeagueEventRankings: PropTypes.func
}