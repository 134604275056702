import axios from 'axios';

export const teamInfoService = {
    updateTeamInfo,
    getTeamInfo
};

function getTeamInfo(leagueId, userId) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'GET',
                        url: process.env.REACT_APP_BASE_URL.concat('/teamInfo'), 
                        headers: { 'authorization': token,
                                    'Content-Type': 'application/json' },
                        params: {leagueId: leagueId, userId: userId}
                    });
    return res;
}

function updateTeamInfo(leagueId, userId, teamName) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'PUT',
                        url: process.env.REACT_APP_BASE_URL.concat('/teamInfo'), 
                        headers: { 'authorization': token,
                                    'Content-Type': 'application/json' },
                        data: {leagueId: leagueId, userId: userId, teamName: teamName}
                    });
    return res;
}