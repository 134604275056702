import React from 'react'
import { Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { userActions } from '../../actions/userActions';
import { passwordResetActions } from '../../actions/passwordResetActions';
import { alertActions } from '../../actions/alertActions';
import queryString from 'query-string';

import logo from '../../constants/logo.png'
import AlertDialog from '../universal/alertDialog'
import Spinner from '../universal/spinner'

const divFormStyle = {
  paddingTop: '25px',
  textAlign: 'center'
};

class PasswordResetForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            usrId: 0,
            newPass: '',
            newPass2: '',
            msg: '',
            disableSave: true,
            acknowledged: false
        };
    }

    componentDidMount() {
        const params = queryString.parse(this.props.location.search);
        this.setState({ usrId: params.usrId });
        this.props.getPasswordReset(params.usrId, params.token);
    }

    handleChangePass = (e) => {
        const newPass = e.target.value;
        this.setState({ newPass: newPass });
        var msg = '';

        if (newPass.length < 5 || newPass.length > 25) {
            msg = 'Password must be between 5 and 25 characters.';
        }
        if (msg.length === 0 && newPass != this.state.newPass2) {
            msg = 'Password do not match.';
        }
        
        this.setState({ msg: msg,
                disableSave: msg.length > 0 });
    }

    handleChangePass2 = (e) => {
        const newPass2 = e.target.value;
        this.setState({ newPass2: newPass2 });
        var msg = '';

        if (newPass2 != this.state.newPass) {
            msg = 'Password do not match.';
        }
        
        this.setState({ msg: msg,
                disableSave: msg.length > 0 });
    }

    handleClose = (e) => {
        this.props.clearAlert();
        this.setState({ acknowledged: true});
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.resetPassword(this.state.usrId, this.state.newPass);  
    }

    renderValidationMessage() {
      if (!this.state.saveEnabled) {
        return (
            <div style={{display: 'flex', justifyContent: 'center', paddingTop: 10}}>
              <Typography style={{paddingTop: 5, align: 'center'}} variant="caption" color="secondary">{this.state.msg}</Typography>
            </div>
          )
      }
      else {
        return null
      }
    }

    renderForm() {
        if (!(this.props.passwordReset.isLoading)) {
            if (this.props.authentication.loggedIn && this.state.acknowledged) {
                return (<div><Redirect to='/asl/roster' /></div>)
            }
            else if (this.props.passwordReset.redirect && this.state.acknowledged) {
                return (
                    <div>
                        <Redirect to={{ pathname: '/signin', state: { from: this.props.location }}} />
                    </div>
                )
            }
            else {
                return (<div style={divFormStyle}>
                    <img src={logo} alt='ASL'/>
                    <form onSubmit={this.handleSubmit}>
                        <div>
                            <TextField disabled label="Email" name="username" value={this.props.passwordReset.email} />
                        </div>
                        <div>
                            <TextField label="New Password" name="newPass" type="password" value={this.state.newPass} onChange={this.handleChangePass}/>
                        </div>
                        <div>
                            <TextField label="Verify Password" name="newPass2" type="password" value={this.state.newPass2} onChange={this.handleChangePass2}/>
                        </div>
                        <Typography align='center' style={{paddingTop: 10}}>
                            <Button variant="raised" type="submit" disabled={this.state.disableSave}>Save</Button>
                        </Typography>
                        {this.renderValidationMessage()}
                    </form>
                </div>);
            }
        }
        else {
            return (<div><Spinner/></div>);
        }
    }

  render() {

    return (
        <div>
            <AlertDialog alert={this.props.alert}
                handleClose={this.handleClose} />
            {this.renderForm()}
        </div>
    );
  }
}

function mapStateToProps(state) {
    const { passwordReset, authentication, alert } = state;
    return {
        passwordReset,
        authentication,
        alert
    };
}

const mapDispatchToProps = dispatch => {
  return {
    getPasswordReset: (userId, token) => dispatch(passwordResetActions.getPasswordReset(userId, token)),
    resetPassword: (userId, password) => dispatch(userActions.resetPassword(userId, password)),
    clearAlert: () => dispatch(alertActions.clearAlert())
  };
}

const connectedPasswordResetForm = connect(mapStateToProps,mapDispatchToProps)(PasswordResetForm);
export { connectedPasswordResetForm as PasswordResetForm }; 