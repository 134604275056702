import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Route  } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import LeagueInfoText from './leagueInfoText';
import Spinner from '../universal/spinner';

import { leagueInfoActions } from '../../actions/leagueInfoActions';

var moment = require('moment-timezone');

class LeagueInfoPage extends Component{

  componentDidMount() {
    this.props.getLeagueInfo(this.props.authentication.user.currentLeague.leagueId);
  }

  getPickTimeText() {
    var pickTime = '';
    if (this.props.leagueInfo.leagueInfo.leagueInfo.pickTimeHrs !== 0) {
      pickTime = this.props.leagueInfo.leagueInfo.leagueInfo.pickTimeHrs + ' Hours';
    }
    if (this.props.leagueInfo.leagueInfo.leagueInfo.pickTimeMins !== 0) {
      pickTime = pickTime + ' ' + this.props.leagueInfo.leagueInfo.leagueInfo.pickTimeMins + ' Mins';
    }
    return pickTime;
  }

  getLocalDate(d) {
    var utcDate = new moment.tz(d, "UTC");
    return utcDate.local().format('ddd, MMM Do h:mm A');
  }


  getDraftHours() {
    var s = moment().tz("UTC").startOf('day');
    var e = moment().tz("UTC").startOf('day');
    var start_hr_min = this.props.leagueInfo.leagueInfo.leagueInfo.draftStartTm.split(':');
    var end_hr_min = this.props.leagueInfo.leagueInfo.leagueInfo.draftEndTm.split(':');
    s.hour(start_hr_min[0]);
    s.minutes(start_hr_min[1]);
    e.hour(end_hr_min[0]);
    e.minutes(end_hr_min[1]);
    return s.local().format('h:mm A') + ' - ' + e.local().format('h:mm A');
  }


    renderLeagueInfo() {
        if (!this.props.leagueInfo.isLoading) {
            return (
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{display: 'flex', flexDirection: 'column', width: 350, paddingTop: 30}}>
                  <LeagueInfoText title='Events:' 
                                  titleVariant='subtitle1'
                                  text={this.props.leagueInfo.leagueInfo.leagueInfo.numberOfEvents}
                                  textVariant='subtitle1'
                                  topPad='5' />
                  <Divider />
                  <LeagueInfoText title='Picks:' 
                                  titleVariant='subtitle1'
                                  text={this.props.leagueInfo.leagueInfo.leagueInfo.numberOfPicks}
                                  textVariant='subtitle1'
                                  topPad='5' />
                  <Divider />
                  <LeagueInfoText title='Teams:' 
                                  titleVariant='subtitle1'
                                  text={this.props.leagueInfo.leagueInfo.leagueInfo.numberOfTeams}
                                  textVariant='subtitle1'
                                  topPad='5' />
                  <Typography variant='caption' style={{paddingTop:10}}>Kentucky Derby will be drafted closer to event date</Typography>
                <Typography variant='h6' style={{paddingTop:25, paddingBottom:10}}>Points</Typography>
                  <LeagueInfoText title='1st Place:' 
                                  titleVariant='subtitle1'
                                  text={this.props.leagueInfo.leagueInfo.leagueInfo.points1}
                                  textVariant='subtitle1'
                                  topPad='5' />
                  <Divider />
                  <LeagueInfoText title='2nd Place:' 
                                  titleVariant='subtitle1'
                                  text={this.props.leagueInfo.leagueInfo.leagueInfo.points2}
                                  textVariant='subtitle1'
                                  topPad='5' />
                  <Divider />
                  <LeagueInfoText title='3rd Place:' 
                                  titleVariant='subtitle1'
                                  text={this.props.leagueInfo.leagueInfo.leagueInfo.points3}
                                  textVariant='subtitle1'
                                  topPad='5' />
                  <Divider />
                  <LeagueInfoText title='4th Place:' 
                                  titleVariant='subtitle1'
                                  text={this.props.leagueInfo.leagueInfo.leagueInfo.points4}
                                  textVariant='subtitle1'
                                  topPad='5' />
                  <Divider />
                  <Typography variant='caption' style={{paddingTop:10}}>No Field Pick</Typography>
                  <Typography variant='caption' style={{paddingTop:5}}>Points lost if no team places</Typography>
                  <Typography variant='caption' style={{paddingTop:5}}>Ties divide points equally</Typography>

                  <Typography variant='h6' style={{paddingTop:25, paddingBottom:10}}>Event Bets Enabled</Typography>
                  <Typography variant='caption' style={{paddingTop:5}}>Commish added througout season</Typography>
                  <Typography variant='caption' style={{paddingTop:5}}>Varying points scale for correct/incorrect</Typography>
                  <Typography variant='caption' style={{paddingTop:5}}>No penalty for obstaining</Typography>

                  <Typography variant='h6' style={{paddingTop:25, paddingBottom:10}}>Draft</Typography>
                  <LeagueInfoText title='Date:' 
                                  titleVariant='subtitle1'
                                  text={this.getLocalDate(this.props.leagueInfo.leagueInfo.leagueInfo.draftDttm)}
                                  textVariant='subtitle1'
                                  topPad='5' />
                  <Typography variant='caption' style={{paddingTop:5, paddingBottom:5}} align='right'>{moment.tz.guess()}</Typography>
                  <Divider />
                  <LeagueInfoText title='Pick Time:' 
                                  titleVariant='subtitle1'
                                  text={this.getPickTimeText()}
                                  textVariant='subtitle1'
                                  topPad='5' />
                  <Divider />
                  <LeagueInfoText title='Hours:' 
                                  titleVariant='subtitle1'
                                  text={this.getDraftHours()}
                                  textVariant='subtitle1'
                                  topPad='5' />
                  <Typography variant='caption' style={{paddingTop:5, paddingBottom:25}} align='right'>{moment.tz.guess()}</Typography>
                </div>
              </div>
            )
        }
        else {
            return (<div><Spinner/></div>);
        }
    }

    render() {
        return (
            <div>
              {this.renderLeagueInfo()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { authentication, leagueInfo } = state;
    return {
        authentication,
        leagueInfo
    };
}

const mapDispatchToProps = dispatch => {
  return {
    getLeagueInfo: (leagueId) => dispatch(leagueInfoActions.getLeagueInfo(leagueId))
  };
}

const connectedLeagueInfoPage = connect(mapStateToProps,mapDispatchToProps)(LeagueInfoPage);
export { connectedLeagueInfoPage as LeagueInfoPage }; 