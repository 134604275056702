import { createMuiTheme } from '@material-ui/core/styles';

const customMenuButton = createMuiTheme({
    overrides:{
        MuiButton: {
            root: {
                color: 'white',
                '&:hover': {
                    backgroundColor: '#7dba71'
                  },
                textTransform: 'none'
                  
              },
        },
    }
});
export default customMenuButton