import axios from 'axios';

export const sportService = {
    getLeagueSports
};

function getLeagueSports(leagueId) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'GET',
                        url: process.env.REACT_APP_BASE_URL.concat('/sport/',leagueId), 
                        headers: { 'authorization': token }
                    });
    return res;

}