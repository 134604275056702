import React, { Component } from 'react';
import { connect } from 'react-redux';

import { eventActions } from '../../../actions/eventActions';
import { uploadRankingsActions } from '../../../actions/admin/uploadRankingsActions';
import { processRankingsActions } from '../../../actions/admin/processRankingsActions';
import { alertActions } from '../../../actions/alertActions';

import Spinner from '../../universal/spinner';
import EventSelector from '../../universal/eventSelector';
import UploadRankings from './uploadRankings';
import ProcessRankings from './processRankings';

import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider } from '@material-ui/core/styles';
import customDisabledTextInput from '../../../styles/themes/customDisabledTextInput';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const steps = ['Upload File', 'Preview Results', 'Submit'];

export default class UpdateRankingsContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedEventIdx: 0,
            activeStep: 0,
            complete: 'N',
            submitted: 'N'
        };
    }

    componentDidMount() {
        this.props.getEvents();
    }

    componentDidUpdate(prevProps) {
      // Typical usage (don't forget to compare props):
      if (!this.props.updateRankings.isLoading && this.props.updateRankings.fileUploaded && !prevProps.updateRankings.fileUploaded) {
        this.props.previewRankings(this.props.events.events[this.state.selectedEventIdx].event_date_id);
        this.handleNext();
      }
    }

    handleDone = () => {
      this.setState({
            selectedEventIdx: 0,
            activeStep: 0,
            complete: 'N',
            submitted: 'N'
      });
    };

    handleNext = () => {
      const { activeStep } = this.state;
      this.setState({
        activeStep: activeStep + 1
      });
    };

    handleBack = () => {
      this.setState(state => ({
        activeStep: state.activeStep - 1,
      }));
    };

    handleEventClick = eventIdx => {
        this.setState({ selectedEventIdx: eventIdx});
        this.props.getEventRankings(this.props.events.events[eventIdx].event_date_id);
    };

    handleCompleteClick = complete => {
      this.setState({ complete: complete ? 'Y' : 'N'});
  };

    handleUpload = (fileName, complete) => {
      this.props.uploadRankings(fileName, this.props.events.events[this.state.selectedEventIdx].event_date_id, complete);
    }

    clearDialog = value => {
      this.props.clearAlert();
      if (this.state.submitted == 'Y') {
        this.handleDone();
      }
    };

    handleMatch = (participantName, participantIdTo) => {
      this.props.rankingsMatch(this.props.events.events[this.state.selectedEventIdx].event_date_id, participantName, participantIdTo);
    }

    handleAdd = (participantName) => {
      this.props.rankingsAdd(this.props.events.events[this.state.selectedEventIdx].event_date_id, participantName);
    }

    handleInclude = (participantId) => {
      this.props.rankingsInclude(this.props.events.events[this.state.selectedEventIdx].event_date_id, participantId);
    }

    handleSubmit = () => {
      this.props.rankingsSubmit(this.props.events.events[this.state.selectedEventIdx].event_date_id, this.state.complete);
      this.setState({ submitted: 'Y'});
    }

    renderUploadRankings() {
      if (this.props.eventRankings.isLoading) {
        return null
      }
      else {
        return (<UploadRankings 
                  handleUpload={this.handleUpload} 
                  clickComplete={this.handleCompleteClick}
                  eventRankings={this.props.eventRankings.eventRankings}
                  eventName={this.props.events.events[this.state.selectedEventIdx].event_name}/>)
      }
    }

    renderFileUpload() {
      return (
          <div style={{display: 'flex', flexDirection: 'column', paddingTop: '20px', alignItems: 'center'}}>
          <div style={{display: 'flex', flexDirection: 'row', paddingBottom: '25px'}}>
            <EventSelector 
                selectedIdx={this.state.selectedEventIdx}
                events={this.props.events.events}
                clickHandler={this.handleEventClick} />
            <MuiThemeProvider theme={customDisabledTextInput}>
              <TextField
                  id="event_date"
                  disabled
                  helperText="Event Date"
                  value={this.props.events.events[this.state.selectedEventIdx].event_date}
                  style = {{width: 120, paddingLeft: 50}}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
              }}
              />
            </MuiThemeProvider>
          </div>
          {this.renderUploadRankings()}
        </div>
      )
    }

    renderProcessRankings() {
      if(!this.props.processRankings.isLoading) {
        return (
          <div>
            <ProcessRankings 
              rankings={this.props.processRankings.processRankings}
              rankingsMatchClick={this.handleMatch}
              rankingsAddClick={this.handleAdd}
              rankingsIncludeClick={this.handleInclude}
              handleBackClick={this.handleBack}
              handleNextClick={this.handleNext} />
          </div>
        )
      }
      else {
        return (<div><Spinner/></div>);
      }
    }

    renderStep() {
        if(!this.props.events.isLoading) {
          if (this.state.activeStep == 0) {
            return (
              <div>
                {this.renderFileUpload()}
              </div>
            );
          }
          if (this.state.activeStep == 1) {
            return (
              <div style={{width: '60%', display: 'flex', justifyContent: 'center'}}>
                {this.renderProcessRankings()}
            </div>
            );
          }
          if (this.state.activeStep == 2) {
            return (
            <div style={{paddingTop: '50px'}}>
              <Button onClick={this.handleSubmit} variant="contained" color="primary">
                  Submit
              </Button>
            </div>
            )
          }
        } else {
          return (<div><Spinner/></div>);
        }
    }

    render() { 

        const d1Style = {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        };

         return (
          <div style={d1Style}>
          <Stepper style={{width: '60%'}} activeStep={this.state.activeStep}>
            {steps.map((label, index) => {
              const props = {};
              const labelProps = {};
              return (
                <Step key={label} {...props}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {this.renderStep()}
          <div>
            <Dialog
              open={this.props.alert.showAlert}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{this.props.alert.message}</DialogTitle>
              <DialogActions>
                <Button onClick={this.clearDialog} color="primary">
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
  return {
    getEvents: () => dispatch(eventActions.getEvents()),
    uploadRankings: (file, eventDateId, complete) => dispatch(uploadRankingsActions.uploadRankings(file, eventDateId, complete)),
    previewRankings: eventDateId => dispatch(processRankingsActions.previewRankings(eventDateId)),
    rankingsMatch: (eventDateId, participantName, participantIdTo) => dispatch(processRankingsActions.rankingsMatch(eventDateId, participantName, participantIdTo)),
    rankingsAdd: (eventDateId, participantName) => dispatch(processRankingsActions.rankingsAdd(eventDateId, participantName)),
    rankingsInclude: (eventDateId, participantId) => dispatch(processRankingsActions.rankingsInclude(eventDateId, participantId)),
    rankingsSubmit: (eventDateId, complete) => dispatch(processRankingsActions.rankingsSubmit(eventDateId, complete)),
    getEventRankings: (eventDateId) => dispatch(eventActions.getEventRankings(eventDateId)),
    clearAlert: () => dispatch(alertActions.clearAlert())
  };
};

function mapStateToProps(state) {
    const { authentication, events, updateRankings, processRankings, eventRankings, alert } = state;
    return {
        authentication,
        events,
        updateRankings,
        processRankings,
        eventRankings,
        alert
    };
}

const connectedUpdateRankingsContainer = connect(mapStateToProps,mapDispatchToProps)(UpdateRankingsContainer);
export { connectedUpdateRankingsContainer as UpdateRankingsContainer }; 