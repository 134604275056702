import axios from 'axios';

export const passwordResetService = {
    resetPassword,
    sendPasswordResetEmail,
    getPasswordReset
};

function sendPasswordResetEmail(email) {
    const res = axios({method: 'POST',
                        url: process.env.REACT_APP_BASE_URL.concat('/passwordResetEmail'), 
                        headers: { 'Content-Type': 'application/json' },
                        data: {email: email}
                    });
    return res;
}

function getPasswordReset(usrId, token) {

    const res = axios({method: 'GET',
                        url: process.env.REACT_APP_BASE_URL.concat('/passwordReset'), 
                        headers: { },
                        params: {usrId: usrId, token: token}
                    });
    return res;
}

function resetPassword(userId, password) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'POST',
                        url: process.env.REACT_APP_BASE_URL.concat('/passwordReset'), 
                        headers: { 'authorization': token,
                                    'Content-Type': 'application/json' },
                        data: {userId: userId, password: password}
                    });
    return res;
}