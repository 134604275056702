import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import DownArrow from '@material-ui/icons/ArrowDropDown';
import UpArrow from '@material-ui/icons/ArrowDropUp';
import InputLabel from '@material-ui/core/InputLabel';
import { MuiThemeProvider } from '@material-ui/core/styles';
import customPointsCaption from '../../styles/themes/customPointsCaption'

export default class RosterCards extends React.Component {

    renderPointsCaption(points) {
        if (points > 0) {
            return (
                <MuiThemeProvider theme={customPointsCaption}>
                  <Typography variant="caption">
                    Points: {points}
                  </Typography>
                </MuiThemeProvider>
            );
        } else {
        return (<Typography variant="caption">
                    Points: {points}
                  </Typography>);
        }
    }

    renderContender(contender){
      if (contender.contender === "Empty") {
        return (
            <div>
              <Typography variant="subheading" style={{backgroundColor: 'lightgray'}}>
                {contender.contender} 
                <InputLabel style={{paddingLeft: 10, paddingRight:15, color: '#000'}}>
                  {contender.eventRank}
                </InputLabel>
              </Typography>
              {this.renderPointsCaption(contender.points)}
            </div>
        )
      }
      else
        return (
            <div>
              <Typography variant="subheading">
                {contender.contender} 
                <InputLabel style={{paddingLeft: 10, paddingRight:15, color: '#000'}}>
                  {contender.eventRank}
                </InputLabel>
              </Typography>
              {this.renderPointsCaption(contender.points)}
            </div>
      )
    }

    renderRoster() {
        if(this.props.roster.length > 0) {
            return (
              <div style={{maxWidth: '1100px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                {this.props.roster.map(rosterItem => (
                  <div style={{paddingRight: 10, paddingBottom: 10}}>
                  <Card raised style={{width: 350, height: 230}}>
                    <CardContent>
                      <Typography variant="headline" component="h2">
                        {rosterItem.eventName}
                      </Typography>
                      <Typography color="textSecondary">
                        {rosterItem.eventDate}
                      </Typography>
                      {rosterItem.contenders.map(contender => 
                          this.renderContender(contender)
                      )}
                    </CardContent>
                  </Card> 
                  </div>))}
              </div>
            );
        } else {
        return (<div style={{paddingTop: '30px'}}>
                  <Typography variant="headline" component="h2">
                        League not yet drafted
                  </Typography>
                </div>);
        }
    }

    render() {
       return (
        <div>{this.renderRoster()}</div>
      );
    }

}


RosterCards.propTypes = {
  roster: PropTypes.object
}