import Promise from 'es6-promise';
import axios from 'axios';

Promise.polyfill();

export const userService = {
    login,
    getUserFromJWT,
    updateCurrentLeague,
    resetPassword,
    logout,
    postContactUs
};

async function login(username, password) {

    const res = await axios({method: 'POST',
                        url: process.env.REACT_APP_BASE_URL.concat('/user/login'), 
                        headers: { 'Content-Type': 'application/json' },
                        data: {'username': username, 'password': password}});

    localStorage.setItem('id_token', res.headers.authorization);
    return res;
}

async function getUserFromJWT(jwt) {

    const res = axios({method: 'GET',
                        url: process.env.REACT_APP_BASE_URL.concat('/user/getUser'), 
                        headers: { 'authorization': jwt }
                    });
    return res;
}

function updateCurrentLeague(user, leagueName) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'PUT',
                        url: process.env.REACT_APP_BASE_URL.concat('/user/getUser'), 
                        headers: { 'authorization': token,
                                    'Content-Type': 'application/json' },
                        data: {'userId': user.id, 'leagueName': leagueName}});
    return res;
}

async function resetPassword(userId, password) {

    const res = await axios({method: 'POST',
                        url: process.env.REACT_APP_BASE_URL.concat('/user/passwordReset'), 
                        headers: { 'Content-Type': 'application/json' },
                        data: {'userId': userId, 'password': password}});

    localStorage.setItem('id_token', res.headers.authorization);
    return res;
}

function postContactUs(email, message) {
    const res = axios({method: 'POST',
                        url: process.env.REACT_APP_BASE_URL.concat('/contactUs'), 
                        headers: {'Content-Type': 'application/json'},
                        data: {'email': email, 'message': message}});
    return res;
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('id_token');
}