import axios from 'axios';

export const standingsService = {
    getStandings
};

function getStandings(leagueId) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'GET',
                        url: process.env.REACT_APP_BASE_URL.concat('/leagueStandings/',leagueId), 
                        headers: { 'authorization': token }
                    });
    return res;

}