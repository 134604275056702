import React, { Component } from 'react';
import { Route  } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import { PrivateRoute } from '../../helpers/privateRoute';
import { TeamInfoPage } from './teamInfoPage'
import { LeagueInfoPage } from './leagueInfoPage'
import { CommishNotesPage } from './commishNotesPage'

class LeagueInfoContainer extends Component{


    render() {
        return (
          <div style={{paddingTop: 30}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 10}}>
                <Link style={{color:'black'}} to="/asl/leagueInfo/teamInfo">
                    <Button size="small">
                        Team Info
                    </Button>
                </Link>
                <div style={{paddingRight: 10}} />
                <Link style={{color:'black'}} to="/asl/leagueInfo/leagueInfo">
                    <Button autoFocus size="small">
                        League Info
                    </Button>
                </Link>
                <div style={{paddingRight: 10}} />
                <Link style={{color:'black'}} to="/asl/leagueInfo/commishNotes">
                    <Button size="small">
                        Commish Notes
                    </Button>
                </Link>
            </div>
            <PrivateRoute path="/asl/leagueInfo/teamInfo" component={TeamInfoPage} />
            <PrivateRoute path="/asl/leagueInfo/leagueInfo" component={LeagueInfoPage} />
            <PrivateRoute path="/asl/leagueInfo/commishNotes" component={CommishNotesPage} />
        </div>)
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication
    };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}

const connectedLeagueInfoContainer = connect(mapStateToProps,mapDispatchToProps)(LeagueInfoContainer);
export { connectedLeagueInfoContainer as LeagueInfoContainer }; 