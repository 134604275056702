import { createMuiTheme } from '@material-ui/core/styles';

const customSvgIcon = createMuiTheme({
    overrides:{
        MuiSvgIcon: {
            root: {
                width: '2em'
              },
        },
    }
});
export default customSvgIcon