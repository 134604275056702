import { userConstants } from '../constants/userConstants';

//let user = JSON.parse(localStorage.getItem('id_token'));
//const initialState = user ? { loggedIn: true, user } : {loggedIn: false};

const initialState = {isLoading: false, loggedIn: false, isResetPass: false};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        isLoading: true,
        loggedIn: false,
        isResetPass: false
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        isLoading: false,
        loggedIn: true,
        isResetPass: false,
        user: action.user.data.user ? action.user.data.user : action.user.data
      };
    case userConstants.LOGIN_FAILURE:
      return {
        isLoading: false,
        loggedIn: false,
        isResetPass: false,
        error: action.error
      };
    case userConstants.LOGOUT:
      return {isLoading: false, loggedIn: false};
    case userConstants.LEAGUE_REQUEST:
      return {
        isLoading: true,
        loggedIn: false,
        isResetPass: false
      };
    case userConstants.LEAGUE_SUCCESS:
      return {
        isLoading: false,
        loggedIn: true,
        isResetPass: false,
        user: action.user.data,
        redirect: false
      };
    case userConstants.LEAGUE_SUCCESS_REDIRECT:
        return {
          isLoading: false,
          loggedIn: true,
          isResetPass: false,
          user: action.user.data,
          redirect: true
        };
    case userConstants.LEAGUE_FAILURE:
      return {
        isLoading: false,
        loggedIn: false,
        isResetPass: false,
        error: action.error
      };
    case userConstants.RESET_PASSWORD_REQUEST:
      return {
        isLoading: true,
        loggedIn: true,
        isResetPass: true
      };
    case userConstants.RESET_PASSWORD_SUCCESS:
      return {
        isLoading: false,
        loggedIn: true,
        isResetPass: false,
        user: action.user.data
      };
    case userConstants.RESET_PASSWORD_FAILURE:
      return {
        isLoading: false,
        loggedIn: false,
        isResetPass: false,
        error: action.error
      };
    default:
      return state
  }
}