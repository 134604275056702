import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';
import DownArrow from '@material-ui/icons/ArrowDropDown';
import UpArrow from '@material-ui/icons/ArrowDropUp';
import { MuiThemeProvider } from '@material-ui/core/styles';
import customBadgeRankUp from '../../../styles/themes/customBadgeRankUp';
import customBadgeRankDown from '../../../styles/themes/customBadgeRankDown';
import customDivider from '../../../styles/themes/customDivider';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';

export default class processRankings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dialogMatchOpen: false,
            matchingName: '',
            matchToParticipantId: 0
        };
    }

    renderUpDownArray(rankChange) {
        if (rankChange == 0) {
            return(null)
        }
        else if (rankChange > 0) {
            return (
                <MuiThemeProvider theme={customBadgeRankUp}>
                    <Badge badgeContent={Math.abs(rankChange)} style={{paddingLeft: 5}}>
                        <UpArrow/>
                    </Badge>
                </MuiThemeProvider>   
            )
        }
        else {
            return (
                <MuiThemeProvider theme={customBadgeRankDown}>
                    <Badge badgeContent={Math.abs(rankChange)} style={{paddingLeft: 5}}>
                        <DownArrow/>
                    </Badge>
                </MuiThemeProvider>
            )
        }
    }

    renderFixMessage(len) {
        if (len > 0) {
            return (
                <Typography style={{paddingBottom:'10px'}} variant="heading" color="secondary">
                    Fix before proceeding. Process all matching before including.
                </Typography>
            )
        }
        else
        {
            return (null)
        }
    }

    renderTypeOfFix(newOdds, oldOdds, newRank, oldRank) {
        if (newOdds != "N/A") {
            return (
                <div>
                    <Typography color="textSecondary">
                        New odds <b>{newOdds}</b>, newRank <b>{newRank}</b>
                    </Typography>
                    <Typography color="textSecondary">
                        Found in file, but no match in existing data.
                    </Typography>
                </div>
            )
        }
        else {
            return (
                <div>
                    <Typography color="textSecondary">
                        Existing odds <b>{oldOdds}</b>, existing rank <b>{oldRank}</b>
                    </Typography>
                    <Typography color="textSecondary">
                        Participant exists in data, but not in found in file.
                    </Typography>
                </div>     
            )
        }
    }

    handleMatch(idx) {
        this.setState({ dialogMatchOpen: true,
                        matchingName: this.props.rankings.fixParticipants[idx].participantName});
    }

    handleInclude(idx) {
        this.props.rankingsIncludeClick(this.props.rankings.fixParticipants[idx].participantId);
    }

    renderFixButton(idx, newOdds) {
        if (newOdds != "N/A") {
            return (
                <Button style={{width: '85px'}} onClick={() => this.handleMatch(idx)} variant="contained" color="primary">
                    Match
                </Button>
                )
        }
        else {
            return (
                <Button style={{width: '85px'}} onClick={() => this.handleInclude(idx)} variant="contained" color="primary" disabled={this.props.rankings.fileOnly.length > 0}>
                    Include
                </Button>
            )
        }
    }

    handleClose = event => {
        this.setState({
            dialogMatchOpen: false,
            matchingName: '',
            matchToParticipantId: 0
        });
    };

    handleMatchChange = event => {
        this.setState({ matchToParticipantId: event.target.value});
    };

    handleMatchRequest = event => {
        this.props.rankingsMatchClick(this.state.matchingName, this.state.matchToParticipantId);
    }

    handleAddRequest = event => {
        this.props.rankingsAddClick(this.state.matchingName);
    }

    ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    renderPlace(place) {
        if (place > 0) {
            return ' | ' + this.ordinal_suffix_of(place) + ' place';
        }
        else {
            return '';
        }
    }

    render() {
        return(
            <div style={{width: '450px', paddingTop: '15px'}}>
                {this.renderFixMessage(this.props.rankings.fixParticipants.length)}
                { this.props.rankings.fixParticipants.map((ranking, idx) => 
                    (<div>
                        <div style={{display: 'flex', flexDirection: 'row', paddingBottom: '5px', backgroundColor: 'lightsalmon', width: '100%'}}>
                            <div style={{display: 'flex', flexDirection: 'column', width: '80%'}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Typography key={idx} variant="subheading">
                                    {ranking.participantName}
                                </Typography>
                                </div>
                                {this.renderTypeOfFix(ranking.newOdds, ranking.oldOdds, ranking.newRank, ranking.oldRank)}
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'right', width: '20%', paddingRight: '15px'}}>
                                {this.renderFixButton(idx, ranking.newOdds)}
                            </div>
                        </div>
                        <MuiThemeProvider theme={customDivider}>
                            <Divider />
                        </MuiThemeProvider>
                    </div>)) }
                { this.props.rankings.matches.map((ranking, idx) => 
                    (<div key={idx} style={{paddingBottom: '8px'}}>
                        <Typography variant="subheading" >
                            {ranking.participantName}
                            {this.renderPlace(ranking.place)}
                            {this.renderUpDownArray(ranking.rankChange)}
                        </Typography>
                        <Typography style={{paddingLeft: '10px'}} color="textSecondary">
                            New odds <b>{ranking.newOdds}</b>, previously <b>{ranking.oldOdds}</b>
                        </Typography>
                        <Typography style={{paddingLeft: '10px', paddingBottom: '5px'}} color="textSecondary">
                            New rank <b>{ranking.newRank}</b>, previously <b>{ranking.oldRank}</b>
                        </Typography>
                        <Divider />
                    </div>)) }
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom:'20px'}}>
                    <div style={{paddingRight: '20px'}}>
                        <Button onClick={this.props.handleBackClick} variant="contained" color="primary">
                            Back
                        </Button>
                    </div>
                    <div>
                        <Button onClick={this.props.handleNextClick} variant="contained" color="primary" disabled={this.props.rankings.fixParticipants.length > 0}>
                            Next
                        </Button>
                    </div>
                </div>
                <Dialog
                    open={this.state.dialogMatchOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Match: {this.state.matchingName}</DialogTitle>
                    <DialogContent>
                        <TextField
                            id="select-event"
                            select
                            helperText="Select Match"
                            value={this.state.matchToParticipantId}
                            onChange={this.handleMatchChange}
                            style = {{width: 300}}
                            margin="normal"
                            >
                            { this.props.rankings.notFound.map((ranking) => 
                                (<MenuItem key={ranking.participantId} value={ranking.participantId}>
                                    {ranking.participantName}
                                </MenuItem>)) }
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleMatchRequest} variant="contained" color="primary" disabled={this.state.matchToParticipantId==0}>
                            Match
                        </Button>
                        <Button onClick={this.handleAddRequest} variant="contained" color="primary" disabled={this.state.matchToParticipantId!=0}>
                            Add New
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

processRankings.propTypes = {
    rankings: PropTypes.arrayOf(PropTypes.object),
    rankingsMatchClick: PropTypes.func,
    rankingsAddClick: PropTypes.func,
    rankingsIncludeClick: PropTypes.func,
    handleBackClick: PropTypes.func,
    handleNextClick: PropTypes.func
  }