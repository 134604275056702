import { eventConstants } from '../constants/eventConstants';

const initialState = {isLoading: true};

export function events(state = initialState, action) {
  switch (action.type) {
    case eventConstants.EVENTS_REQUEST:
      return {
        isLoading: true
      };
    case eventConstants.EVENTS_SUCCESS:
      return {
        isLoading: false,
        firstEvent: action.events[0].event_name,
        events: action.events
      };
    case eventConstants.EVENT_INFO_FAILURE:
      return {
        isLoading: false
      };
    default:
      return state
  }
}