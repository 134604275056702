import { createMuiTheme } from '@material-ui/core/styles';


const customSigninExpansion = createMuiTheme({
    overrides:{
        MuiExpansionPanelSummary: {
            content: {
                  width: '100%',
                  textAlign: 'right'
            }
        },
        MuiExpansionPanelDetails: {
            root: {
                backgroundColor: '#21618C'
            }
        },
        MuiCollapse: {
            container: {
                boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: 'transparent'
            },
            elevation1: {
                boxShadow: 'none'
            }
        },
        MuiTypography: {
            body1: {
                width: '100%',
                color: 'black',
                fontWeight: 400
            }
        },
        MuiSvgIcon: {
            root: {
                fill: 'black'
            }
        },
        MuiFormLabel: {
            root: {
                color: 'black',
                fontWeight: 400,
                '&$focused': {
                    color: 'white'
                },
                zIndex: 2,
                fontSize: '0.8rem'
            }
        },
        MuiInputLabel: {
            shrink: {
                color: 'white'
            }
        },
        MuiButton: {
            root: {
                textTransform: 'none'
            }
        },
        MuiInputBase: {
            input: {
                background: 'floralwhite'
            }
        },

    }
});
export default customSigninExpansion;