import { draftConstants } from '../constants/draftConstants';

const initialState = {isLoading: true};

export function draftOrder(state = initialState, action) {
  switch (action.type) {
    case draftConstants.DRAFT_ORDER_REQUEST:
      return initialState;
    case draftConstants.DRAFT_ORDER_SUCCESS:
      return {
        isLoading: false,
        draftOrder: action.draftOrder
      };
    case draftConstants.DRAFT_ORDER_FAILURE:
      return {
        isLoading: true
      };
    default:
      return state
  }
}