import axios from 'axios';

export const leagueInfoService = {
    getLeagueInfo
};

function getLeagueInfo(leagueId) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'GET',
                        url: process.env.REACT_APP_BASE_URL.concat('/leagueInfo'), 
                        headers: { 'authorization': token,
                                    'Content-Type': 'application/json' },
                        params: {leagueId: leagueId}
                    });
    return res;
}