import axios from 'axios';

export const processRankingsService = {
    previewRankings,
    rankingsMatch,
    rankingsAdd,
    rankingsInclude,
    rankingsSubmit
};

function previewRankings(eventDateId) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'GET',
                        url: process.env.REACT_APP_BASE_URL.concat('/admin/processRankings'), 
                        headers: { 'authorization': token },
                        params: {
                            eventDateId : eventDateId
                        },
                        timeout: 4000
                    });
    return res;
}

function rankingsMatch(eventDateId, participantName, participantIdTo) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'POST',
                        url: process.env.REACT_APP_BASE_URL.concat('/admin/processRankings'), 
                        headers: { 'authorization': token,
                                    'Content-Type': 'application/json' },
                        data: {type: 'match', 
                                eventDateId: eventDateId, 
                                participantName: participantName,
                                participantIdTo: participantIdTo},
                        timeout: 4000
                    });
    return res;
}

function rankingsAdd(eventDateId, participantName) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'POST',
                        url: process.env.REACT_APP_BASE_URL.concat('/admin/processRankings'), 
                        headers: { 'authorization': token,
                                    'Content-Type': 'application/json' },
                        data: {type: 'add', 
                                eventDateId: eventDateId, 
                                participantName: participantName},
                        timeout: 4000
                    });
    return res;
}

function rankingsInclude(eventDateId, participantId) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'POST',
                        url: process.env.REACT_APP_BASE_URL.concat('/admin/processRankings'), 
                        headers: { 'authorization': token,
                                    'Content-Type': 'application/json' },
                        data: {type: 'include', 
                                eventDateId: eventDateId, 
                                participantId: participantId},
                        timeout: 4000
                    });
    return res;
}

function rankingsSubmit(eventDateId, complete) {
    var token = localStorage.getItem('id_token');

    const res = axios({method: 'POST',
                        url: process.env.REACT_APP_BASE_URL.concat('/admin/processRankings'), 
                        headers: { 'authorization': token,
                                    'Content-Type': 'application/json' },
                        data: {type: 'submit', 
                                eventDateId: eventDateId, 
                                complete: complete},
                        timeout: 4000
                    });
    return res;
}