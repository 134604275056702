import { leagueInfoConstants } from '../constants/leagueInfoConstants';
import { leagueInfoService } from '../services/leagueInfoService';
import { alertActions } from './alertActions';

export const leagueInfoActions = {
    getLeagueInfo
};

function getLeagueInfo(leagueId) {
    return dispatch => {
        dispatch(request());

        leagueInfoService.getLeagueInfo(leagueId)
            .then(
                leagueInfo => { 
                    dispatch(success(leagueInfo.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: leagueInfoConstants.LEAGUE_INFO_REQUEST, text: "Request Made" } }
    function success(leagueInfo) { return { type: leagueInfoConstants.LEAGUE_INFO_SUCCESS, leagueInfo } }
    function failure() { return { type: leagueInfoConstants.LEAGUE_INFO_FAILURE, text: "Request Failed" } }
}