import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types'

export default class TeamSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selected: this.props.selected
        };
    }


  handleChange = name => event => {
    if(event.target.value && event.target.value !== this.state.selected) {
      this.setState({
        selected: event.target.value
      });
      this.props.getRoster(this.props.leagueId, event.target.value);
    }
  };

    render() {   
       return (
          <div style={{paddingLeft: '10px'}}>
            <TextField
              id="select-team"
              select
              helperText="Select Team"
              value={this.state.selected}
              onChange={this.handleChange('select')}
              style = {{width: 175}}
              margin="normal"
            >
                { this.props.teams.map(teams => (<MenuItem key={teams.userId} value={teams.userId}>{teams.teamName}</MenuItem>)) }
            </TextField>
          </div>
      )
    }
}


TeamSelector.propTypes = {
  selected: PropTypes.string,
  leagueId: PropTypes.string,
  teams: PropTypes.arrayOf(PropTypes.object),
  getRoster: PropTypes.func
}