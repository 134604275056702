import { createMuiTheme } from '@material-ui/core/styles';

const customMenuCreateButton = createMuiTheme({
    overrides:{
        MuiButton: {
            root: {
                color: 'white',
                backgroundColor: '#21c43c',
                '&:hover': {
                    backgroundColor: '#21c43c'
                  },
                textTransform: 'none'
              },
        },
    }
});
export default customMenuCreateButton