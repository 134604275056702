import { createMuiTheme } from '@material-ui/core/styles';

const customPointsCaption = createMuiTheme({
    overrides:{
      MuiTypography: {
        caption: {
          color: 'rgba(0, 128, 0, 1)',
          backgroundColor: 'lightgray',
          width: '70px',
          fontWeight: 600
        }
      }
    }
});
export default customPointsCaption;